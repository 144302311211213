import React from "react";
import BtnGroup from "../../../items/btn-group/btn-group";
import { getLocalStorageData, getGameEngineData } from "../../../../utils/utils";
import i18n from "../../../../utils/i18n";

let options = [
    { label: i18n.t('translations:steps:sleepLength:labelNeptune', { count: 1210 }), value: "1" },
    { label: i18n.t('translations:steps:sleepLength:labelAltair', { count: 1790 }), value: "2" },
    { label: i18n.t('translations:steps:sleepLength:labelAltair', { count: 1860 }), value: "3" },
    { label: i18n.t('translations:steps:sleepLength:labelAltair', { count: 1940 }), value: "4" }
]

interface SleepLengthStates{
  sleepLength : string | null;
}

export default class SleepLength extends React.Component<any,SleepLengthStates> {


    constructor(props: any) {
      super(props)
      let data = getLocalStorageData()
      if (data.sleepLength.data) {
        this.state = {
          sleepLength: (data.sleepLength.data.length != null)? data.sleepLength.data.length : null
        };
      } else {
        this.state = {
          sleepLength: null
        };
      }
    }

    onChange(v: any) {
      let data = getLocalStorageData()
      if (!data.sleepLength.data) {
        data.sleepLength.data = {
          length: v
        }
      } else {
        data.sleepLength.data.length = v
      }
      this.setState({sleepLength:v});
      let gameData = getGameEngineData()
      if (v == "2" || v == "3" || v == "4") {
        let sdecs = "none"
        switch (v) {
          case "2":
            sdecs = "58"
            break
          case "3":
            sdecs = "65"
            break
          case "4":
            sdecs = "73"
            break
        }
        gameData.data.sdecs = sdecs
        gameData.data.pieds = data.seatWidth.data === null || data.seatWidth.data === undefined || (data.seatWidth.data.width !== "A-L" && data.seatWidth.data.width !== "A-R")
          ? "pied"
          : data.armrest.data !== null && data.armrest.data !== undefined && data.seatWidth.data.width === "A-L"
            ? "pied_g"
            : "pied_d"
      } else {
        gameData.data.sdecs = "empty"
        gameData.data.pieds = data.seatWidth.data === null || data.seatWidth.data === undefined || (data.seatWidth.data.width !== "A-L" && data.seatWidth.data.width !== "A-R")
          ? "neptune"
          : data.armrest.data !== null && data.armrest.data !== undefined && data.seatWidth.data.width === "A-L"
            ? "neptune_g"
            : "neptune_d"
      }
    }

    render() {
      return (
        <BtnGroup title={''} options={options} selectedOptionValue={this.state.sleepLength} onSelect={(v)=>this.onChange(v)}/>
      )
    }
}
