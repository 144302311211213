export default {
  previous: "Anterior",
  next: "Siguiente",
  validate: "Validar",
  download: "Descargar",
  completingSetup: "Finalización de la configuración",
  enterConfigurationCode: "Ingrese el código de configuración",
  left: "Izquierda",
  right: "Derecha",
  yes: "si",
  no: "no",
  with: "con",
  without: "sin",
  restart: "Reiniciar",
  other: "Otro",
  steps: {
    seatWidth: {
      title: "Ancho del banco",
      seatNumber: {
        title: "Número de plazas",
        label: "{{count}} plaza",
        label_plural: "{{count}} plazas"
      },
      seatWidth: {
        title: "Ancho del banco",
        labelLeft: "{{count}} mm izquierda",
        labelRight: "{{count}} mm derecha",
        label: "{{count}} mm"
      }
    },
    sleepLength: {
      title: "Longitud del dormitorio",
      labelAltair: "ALTAIR (Longitud del dormitorio : {{count}} mm)",
      labelNeptune: "NEPTUNE (Longitud del dormitorio : {{count}} mm)"
    },
    armRest: {
      title: "apoyabrazos",
      without: "sin",
      left: "Izquierda",
      right: "derecha",
      leftRight: "izquierda y derecha",
      center: "central",
      centerLeftRight: "central, izquierda y derecha ",
      all: "2 en el centro, izquierda y derecha"
    },
    clothDist: {
      title: "Distribución de tejidos",
      without: "sin cobertura",
      one: "una sola tela ",
      two: "Dos tonos"
    },
    clothes: {
      title: "Tejido",
      label: "Tejido {{count}}",
      labelRev1: "Tejido 1 (banda acolchada)",
      labelRev2: "Tejido 2 (resto del asiento)",
      noElementMessage: "Ha elegido sin tela, vaya directamente a la siguiente",
      constructorsLabel: "Gama",
      texturesLabel: "Tejidos",
      brands: {
        rib: "RIB",
        ford: "FORD",
        mercedes: "MERCEDES",
        opel: "OPEL",
        psa: "PSA",
        renault: "RENAULT",
        volkswagen: "VOLKSWAGEN"
      },
      textures: {
        draduro7001: "Draduro 7001",
        draduro8009: "Draduro 8009",
        draduro8032: "Draduro 8032",
        draduro8212: "Draduro 8212",
        draduro8335: "Draduro 8335",
        draduro8901: "Draduro 8901",
        draduro8915: "Draduro 8915",
        enduro470: "Enduro 470",
        enduro1478: "Enduro 1478",
        enduro1677: "Enduro 1677",
        enduro2687: "Enduro 2687",
        enduro7005: "Enduro 7005",
        enduro8015: "Enduro 8015",
        enduro8440: "Enduro 8440",
        enduro8504: "Enduro 8504",
        enduro8514: "Enduro 8514",
        enduro8800: "Enduro 8800",
        enduro8841: "Enduro 8841",
        enduro8900: "Enduro 8900",
        enduro8923: "Enduro 8923",
        resistRosa: "Resist Rosa",
        resistAzaleaBlack: "Resist Azalea Black",
        black: "DRADURON",
        fTransitcustom: "FORD TRANSIT CUSTOM",
        forduni: "FORD MAX",
        mercedeslima: "MERCEDES LIMA",
        mercedestunja: "TUNJA MOTIVOS",
        mercedestunjauni: "Tunja unido",
        limamotif: "LIMA MOTIVOS",
        limauni: "LIMA UNIDO",
        oVivaroconnectm: "VIVARO CONNECT M",
        oVivaroconnectu: "VIVARO CONNECT U",
        peugeotboxer: "PEUGEOT BOXER",
        psakobrasilia: "PSA KO BRASILIA",
        psakocuritiba: "PSA KO CURITIBA",
        fiatscudo: "FIAT SCUDO",
        scudobleuuni: "SCUDO BLUE",
        fDucato2014m: "FIAT Crêpe 157 Gris",
        fDucato2014u: "FIAT Crêpe 157 Noir",
        renaultdino: "RENAULT DINO",
        renaultkario: "RENAULT KARIO",
        safariBlack: "SAFARI NOIR",
        renaultx82java: "RENAULT JAVA",
        t5boxuni: "T5 BOX UNIDO",
        tasamouni: "TASAMO UNIDO",
        vwaustin: "VW AUSTIN",
        vwbricks: "VW BRICKS",
        vwdoublegrid: "VW DOUBLE GRID",
        vwt4inca: "VW T4 INCA",
        vwt5incaii: "VW T5 INCA II",
        vwt5place: "VW T5 PLACE",
        vwt6simora: "VW T6 SIMORA",
        titane: "TITANE",
        kompo: "RENAULT KOMPO"
      }
    },
    handlePad: {
      title: "Maneta en el eje desplazada",
      handleAxleLeft: "Maneta en el eje a la izquierda",
      handleAxleRight: "Maneta en el eje a la derecha",
      integratedAxleLeft: "IManeta integrada a la izquierda",
      integratedAxleRight: "Maneta integrada a la derecha",
      handleAxlePdadLeft: "Maneta en el eje a la izquierda movida de + {{count}} grados",
      handleAxlePdadRight: "Maneta en el eje a la derecha movida de + {{count}} grados",
    },
    constructor: {
      title: "fabricante",
      titleType: "Type",
      brands: {
        citroen: "CITROËN",
        fiat: "FIAT",
        ford: "FORD",
        man: "MAN",
        mercedes: "MERCEDES",
        nissan: "NISSAN",
        opel: "OPEL - VAUXHALL",
        peugeot: "PEUGEOT",
        renault: "RENAULT",
        toyota: "TOYOTA",
        volkswagen: "VOLKSWAGEN"
      }
    },
    frameType: {
      title: "Tipo de pie"
    },
    drivingPosition: {
      title: "Puesto de conducción"
    },
    fixationFrame: {
      title: "Interface"
    },
    benchseatPosition: {
      title: "Posición del banco",
      maxiFront: "Máximo en la parte delanter",
      maxiRear: "Máximo en la parte trasera",
      other: "Otro",
    },
    sleepingHeight: {
      title: "Altura cama"
    },
    sdecs: {
      title: "SDEC + SDECS (recorte en el cojín del respaldo)",
      values: {
        without: "Sin",
        "80_X_100": "80X100",
        "90_X_100": "90X100",
        "90_X_140": "90X140",
        "100_X_200": "100X200",
        "140_X_140": "140X140",
        "140_X_200": "140X200"
      }
    },
    thermoflex: {
      title: "Sobrecolchón 3D"
    },
    isofix: {
      title: "ISOFIX Toptether"
    },
    squareArticulation: {
      title: "Cuadrado en plato"
    },
    wheelArchOffsetHeight: {
      title: "Altura por debajo del desplazamiento del paso de rueda"
    },
    wheelArchOffsetLeft: {
      title: "desplazamiento para paso de rueda a la izquierda"
    },
    wheelArchOffsetRight: {
      title: "desplazamiento para paso de rueda a la derecha"
    },
    fixedFrameVersion: {
      title: "versión pie fijo",
      values: {
        without: "con pie fijo",
        fixed: "pie fijo",
        c64: "C64",
        c66d: "C66D",
        c66g: "C66G"
      }
    }
  }
}
