import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { resetLocalStorageData } from './utils/utils'
import queryString from "query-string";
import Configurator from './pages/configurator';
import Questions from './pages/questions';
import { getRoute, Route } from './utils/route_parser';
import i18nextInstance, { changeLang } from './utils/i18n';
import { I18nextProvider } from 'react-i18next';
import { i18n } from 'i18next';

let query = queryString.parseUrl(location.href).query;

const Main = ({i18n}: {i18n: i18n}) => {

  let [route, setRoute] = useState(getRoute())

  window.addEventListener("hashchange",async function(event) {
		let nRoute = getRoute()
    if (nRoute != route) setRoute(nRoute)
    await changeLang(i18n)
	});

  switch (route) {
    case Route.REVENDEUR:
      return (
        <Questions query={query}/>
      )
    case Route.HOME:
      return (
        <Configurator />
      )
  }
}

window.onload = function () {
  resetLocalStorageData();
  ReactDOM.render(
    <I18nextProvider i18n={i18nextInstance}>
      <Main i18n={i18nextInstance}/>
    </I18nextProvider>,
    document.getElementById("main")
  );
}
