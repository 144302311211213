import React from "react";
import { EMaterial } from "../../../../utils/gameengine_data";
import i18n from "../../../../utils/i18n";
import { getLocalStorageData, setMaterial } from "../../../../utils/utils";
import BtnGroup from "../../../items/btn-group/btn-group";

let options = [
  { label: i18n.t('translations:steps:clothDist:without'), value: "A" },
  { label: i18n.t('translations:steps:clothDist:one'), value: "B" },
  { label: i18n.t('translations:steps:clothDist:two'), value: "C" }
]

interface ClothDistributionStates{
  clothDistribution: string | null;
}

export default class ClothDistribution extends React.Component<any,ClothDistributionStates> {

    constructor (props : any) {
        super(props)
        let data = getLocalStorageData()
        if (data.clothDist.data) {
          this.state = {
            clothDistribution: (data.clothDist.data.clothDistribution != null)? data.clothDist.data.clothDistribution : null
          }
        } else {
          this.state = {
            clothDistribution: null
          }
        }
    }

    onChange (v: any) {
        let data = getLocalStorageData()
        if (!data.clothDist.data) {
            data.clothDist.data = {
                clothDistribution: v
            }
        } else {
            data.clothDist.data.clothDistribution = v
        }

        if (v === 'A') {
          setMaterial(EMaterial.MATERIAL_GLOBAL, "none");
          setMaterial(EMaterial.MATERIAL_BACK, "none");
          setMaterial(EMaterial.MATERIAL_MATELASSE, "none");
        }

        this.setState({clothDistribution: v})
    }

    render () {
      return (
        <BtnGroup title={''} options={options} selectedOptionValue={this.state.clothDistribution} onSelect={(v)=>this.onChange(v)}/>
      )
    }
}
