import React from "react";
import './breadcrumb-circle.scss';

interface ButtonProps {
  key: string,
  active: boolean,
  selected: boolean,
  selected_icon: boolean,
  icon_path: string | null,
  onClick: () => void,
}

export default class BreadcrumbCircle extends React.Component <ButtonProps, {}> {

  constructor (props : ButtonProps) {
    super (props)
  }

  render () {
    return (
      <div onClick={()=>this.props.onClick()} className={`breadcrumb-circle ${this.props.icon_path?'icon':''} ${this.props.selected?'selected':''} ${this.props.selected_icon?'selected_icon':''} ${this.props.active?'active':''}`}>
        {this.props.icon_path? <img src={this.props.icon_path} alt={`icon`}/> :null}
      </div>
    )
  }

}
