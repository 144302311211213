import React from "react";
import { EMaterial } from "../../../../utils/gameengine_data";
import i18n from "../../../../utils/i18n";
import { getLocalStorageData, setMaterial } from "../../../../utils/utils";
import ColorSelector, { ColorSelectorOption } from "../../../items/color-selector/color-selector";

let options : ColorSelectorOption[] = [
    {
        brand: i18n.t('translations:steps:clothes:brands:rib'),
        textures: [
            { label: i18n.t('translations:steps:clothes:textures:safariBlack'), value: 'safariBlack', img: './assets/img/textures/renaultkario.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:draduro7001'), value: 'draduro7001', img: './assets/img/textures/draduro7001.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:draduro8009'), value: 'draduro8009', img: './assets/img/textures/draduro8009.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:draduro8032'), value: 'draduro8032', img: './assets/img/textures/draduro8032.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:draduro8212'), value: 'draduro8212', img: './assets/img/textures/draduro8212.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:draduro8335'), value: 'draduro8335', img: './assets/img/textures/draduro8335.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:draduro8901'), value: 'draduro8901', img: './assets/img/textures/draduro8901.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:draduro8915'), value: 'draduro8915', img: './assets/img/textures/draduro8915.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:black'), value: 'noir', img: './assets/img/textures/noir.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:enduro470'), value: 'enduro470', img: './assets/img/textures/enduro470.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:enduro1478'), value: 'enduro1478', img: './assets/img/textures/enduro1478.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:enduro1677'), value: 'enduro1677', img: './assets/img/textures/enduro1677.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:enduro2687'), value: 'enduro2687', img: './assets/img/textures/enduro2687.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:enduro7005'), value: 'enduro7005', img: './assets/img/textures/enduro7005.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:enduro8015'), value: 'enduro8015', img: './assets/img/textures/enduro8015.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:enduro8440'), value: 'enduro8440', img: './assets/img/textures/enduro8440.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:enduro8504'), value: 'enduro8504', img: './assets/img/textures/enduro8504.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:enduro8514'), value: 'enduro8514', img: './assets/img/textures/enduro8514.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:enduro8800'), value: 'enduro8800', img: './assets/img/textures/enduro8800.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:enduro8841'), value: 'enduro8841', img: './assets/img/textures/enduro8841.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:enduro8900'), value: 'enduro8900', img: './assets/img/textures/enduro8900.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:enduro8923'), value: 'enduro8923', img: './assets/img/textures/enduro8923.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:resistRosa'), value: 'resistRosa', img: './assets/img/textures/resistRosa.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:resistAzaleaBlack'), value: 'resistAzaleaBlack', img: './assets/img/textures/resistAzaleaBlack.jpg' },
        ]
    },
    {
        brand: i18n.t('translations:steps:clothes:brands:ford'),
        textures: [
            // { label: i18n.t('translations:steps:clothes:textures:fTransitcustom'), value: 'f-transitcustom', img: './assets/img/textures/f-transitcustom.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:forduni'), value: 'forduni', img: './assets/img/textures/forduni.jpg' },
        ]
    },
    {
        brand: i18n.t('translations:steps:clothes:brands:mercedes'),
        textures: [
            { label: i18n.t('translations:steps:clothes:textures:mercedestunja'), value: 'mercedestunja', img: './assets/img/textures/mercedestunja.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:mercedestunjauni'), value: 'mercedestunjauni', img: './assets/img/textures/mercedestunjauni.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:limamotif'), value: 'limamotif', img: './assets/img/textures/limamotif.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:limauni'), value: 'limauni', img: './assets/img/textures/limauni.jpg' },
        ]
    },
    {
        brand: i18n.t('translations:steps:clothes:brands:opel'),
        textures: [
            { label: i18n.t('translations:steps:clothes:textures:oVivaroconnectm'), value: 'o-vivaroconnectm', img: './assets/img/textures/o-vivaroconnectm.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:oVivaroconnectu'), value: 'o-vivaroconnectu', img: './assets/img/textures/o-vivaroconnectu.jpg' },
        ]
    },
    {
        brand: i18n.t('translations:steps:clothes:brands:psa'), // Peugeot, Citroën, Fiat
        textures: [
            { label: i18n.t('translations:steps:clothes:textures:peugeotboxer'), value: 'peugeotboxer', img: './assets/img/textures/peugeotboxer.jpg' }, // Peugeot
            { label: i18n.t('translations:steps:clothes:textures:psakobrasilia'), value: 'psakobrasilia', img: './assets/img/textures/psakobrasilia.jpg' }, // Peugeot
            { label: i18n.t('translations:steps:clothes:textures:psakocuritiba'), value: 'psakocuritiba', img: './assets/img/textures/psakocuritiba.jpg' }, // Peugeot
            { label: i18n.t('translations:steps:clothes:textures:fiatscudo'), value: 'fiatscudo', img: './assets/img/textures/fiatscudo.jpg' }, // Fiat
            { label: i18n.t('translations:steps:clothes:textures:scudobleuuni'), value: 'scudobleuuni', img: './assets/img/textures/scudobleuuni.jpg' }, // Fiat
            { label: i18n.t('translations:steps:clothes:textures:fDucato2014m'), value: 'f-ducato2014m', img: './assets/img/textures/f-ducato2014m.jpg' }, // Ducato -> Fiat
            { label: i18n.t('translations:steps:clothes:textures:fDucato2014u'), value: 'f-ducato2014u', img: './assets/img/textures/f-ducato2014u.jpg' }, // Ducato -> Fiat
        ]
    },
    {
        brand: i18n.t('translations:steps:clothes:brands:renault'),
        textures: [
            { label: i18n.t('translations:steps:clothes:textures:renaultdino'), value: 'renaultdino', img: './assets/img/textures/renaultdino.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:renaultx82java'), value: 'renaultx82java', img: './assets/img/textures/renaultx82java.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:renaultkario'), value: 'renaultkario', img: './assets/img/textures/renaultkario.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:kompo'), value: 'kompo', img: './assets/img/textures/kompo.jpg' },
        ]
    },
    {
        brand: i18n.t('translations:steps:clothes:brands:volkswagen'),
        textures: [
            { label: i18n.t('translations:steps:clothes:textures:t5boxuni'), value: 't5boxuni', img: './assets/img/textures/t5boxuni.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:tasamouni'), value: 'tasamouni', img: './assets/img/textures/tasamouni.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:vwaustin'), value: 'vwaustin', img: './assets/img/textures/vwaustin.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:vwbricks'), value: 'vwbricks', img: './assets/img/textures/vwbricks.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:vwdoublegrid'), value: 'vwdoublegrid', img: './assets/img/textures/vwdoublegrid.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:vwt4inca'), value: 'vwt4inca', img: './assets/img/textures/vwt4inca.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:vwt5incaii'), value: 'vwt5incaii', img: './assets/img/textures/vwt5incaii.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:vwt5place'), value: 'vwt5place', img: './assets/img/textures/vwt5place.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:vwt6simora'), value: 'vwt6simora', img: './assets/img/textures/vwt6simora.jpg' },
            { label: i18n.t('translations:steps:clothes:textures:titane'), value: 'titane', img: './assets/img/textures/titane.jpg' },
        ]
    }
]

interface ClotheState {
  brand: string | null,
  option: string | null
}

interface ClothesStates{
  cloth_1: ClotheState | null;
  cloth_2: ClotheState | null;
}

export default class Clothes extends React.Component<any,ClothesStates> {

    constructor(props: any) {
        super(props)
        let data = getLocalStorageData()
        if (data.clothes.data) {
          setMaterial(EMaterial.MATERIAL_GLOBAL, data.clothes.data.cloth_1 || "none");
          setMaterial(EMaterial.MATERIAL_BACK, data.clothes.data.cloth_1 || "none");
          setMaterial(EMaterial.MATERIAL_MATELASSE, data.clothes.data.cloth_2 || "none");
          let originOption1 = options.find(option => option.textures.find(texture => texture.value === data.clothes.data.cloth_1));
          let brand1 = originOption1?.brand || null;

          let originOption2 = options.find(option => option.textures.find(texture => texture.value === data.clothes.data.cloth_2));
          let brand2 = originOption2?.brand || null;

          this.state = {
            cloth_1 : {
              brand: brand1,
              option: (data.clothes.data.cloth_1 != null) ? data.clothes.data.cloth_1 : null
            },
            cloth_2 : {
              brand: brand2,
              option: (data.clothes.data.cloth_2 != null) ? data.clothes.data.cloth_2 : null
            }
          }
        } else {
          this.state = {
            cloth_1 :  null,
            cloth_2 :  null
          }
        }
    }

    onChangeCloth1(option: string | null, brand: string) {
        let data = getLocalStorageData()
        if (!data.clothes.data) {
            data.clothes.data = {
                cloth_1: option,
                cloth_2: null
            }
        } else {
            data.clothes.data.cloth_1 = option
        }
        this.updateMaterial();
        this.setState({
          cloth_1: {
            option,
            brand
          },
          cloth_2: {
            option: null,
            brand: null
          }
        })
    }

    onChangeCloth2(option: string | null, brand: string) {
        let data = getLocalStorageData()
        if (!data.clothes.data) {
            data.clothes.data = {
                cloth_1: null,
                cloth_2: option
            }
        } else {
            data.clothes.data.cloth_2 = option
        }
        this.updateMaterial();
        this.setState({
          cloth_2: {
            option,
            brand
          }
        })
    }

    updateMaterial() {
        let data = getLocalStorageData()
        let clothDistribution = (getLocalStorageData().clothDist.data) ? (getLocalStorageData().clothDist.data as any).clothDistribution : undefined;
        let nClothes = 0;
        if (['B', 'C'].includes(clothDistribution)) nClothes = 1;
        if (['C'].includes(clothDistribution)) nClothes = 2;
        if (data.clothes.data === undefined) return;
        if (nClothes === 1) {
            setMaterial(EMaterial.MATERIAL_GLOBAL, data?.clothes.data?.cloth_1 || "none");
            setMaterial(EMaterial.MATERIAL_BACK, data?.clothes.data?.cloth_1 || "none");
            setMaterial(EMaterial.MATERIAL_MATELASSE, data?.clothes.data?.cloth_1 || "none");
        }
        if (nClothes === 2) {
            setMaterial(EMaterial.MATERIAL_GLOBAL, data?.clothes.data?.cloth_2 || "none");
            setMaterial(EMaterial.MATERIAL_BACK, data?.clothes.data?.cloth_2 || "none");
            setMaterial(EMaterial.MATERIAL_MATELASSE, data?.clothes.data?.cloth_1 || "none");
        }
    }

    filterByBrand(options: ColorSelectorOption[]) : any[] {
        let brand = this.state.cloth_1?.brand || null;
        return options.filter(e => {
            if (brand === null) {
                return true;
            } else if (brand === i18n.t('translations:steps:clothes:brands:rib')) {
                return true;
            } else if (brand === e.brand || e.brand === i18n.t('translations:steps:clothes:brands:rib')) {
                return true;
            } else {
                return false;
            }
        });
    }

    render() {
        let cloth_1El = null
        let cloth_2El = null
        let noElementMessage = null

        let repartitionTissus = (getLocalStorageData().clothDist.data) ? (getLocalStorageData().clothDist.data as any).clothDistribution : undefined;
        if (repartitionTissus && ['B', 'C'].includes(repartitionTissus)) {
            cloth_1El = (
                <ColorSelector
                    title={i18n.t('translations:steps:clothes:label', {count: 1})}
                    state={this.state.cloth_1?.option || null}
                    onChange={this.onChangeCloth1.bind(this)}
                    options={options}
                />
            )
        }

        if (repartitionTissus && ['C'].includes(repartitionTissus)) {
            cloth_2El = (
                <ColorSelector
                    title={i18n.t('translations:steps:clothes:label', {count: 2})}
                    state={this.state.cloth_2?.option || null}
                    onChange={this.onChangeCloth2.bind(this)}
                    options={this.filterByBrand(options)}
                />
            )
        }

        if (cloth_1El === null && cloth_2El === null) noElementMessage = <p>{i18n.t('translations:steps:clothes:noElementMessage')}</p>

        return (
            <div>
                {noElementMessage}
                {cloth_1El}
                {cloth_2El}
            </div>
        )
    }
}
