import React from "react";
import './breadcrumb-mobile-group.scss';
import BreadcrumbMobileGroupDot from "../breadcrumb-mobile-group-dot/breadcrumb-mobile-group-dot";'../breadcrumb-mobile-group-dot/breadcrumb-mobile-group-dot';

interface ButtonProps {
  key: string,
  childStartKey: string,
  dots: any[],
  icon_path: string,
  onClick: () => void,
}

export default class BreadcrumbMobileGroup extends React.Component <ButtonProps, {}> {

  constructor (props : ButtonProps) {
    super (props)
  }

  render () {
    let self = this;
    let dots : any[] = [];
    this.props.dots.forEach(function(v, k){
      dots.push(<BreadcrumbMobileGroupDot key={self.props.childStartKey + '-dot-' + k} active={v.active} selected={v.selected} onClick={()=>{}}/>)
    });
    return (
      <div onClick={()=>this.props.onClick()} className={`breadcrumb-mobile-group`}>
        <div className={'icon-container'}>
          <img src={this.props.icon_path.slice(0, -4) + '-active.svg'} alt="group_icon" className="icon"/>
        </div>
        <div className="dots">
          {dots}
        </div>
      </div>
    )
  }

}
