import React from "react";
import './button.scss';

interface ButtonProps {
    onClick: (e : any) => void,
    selected: boolean
}

export default class Button extends React.Component <ButtonProps, {}> {

    constructor (props : ButtonProps) {
        super (props)
    }

    render () {
        return (
            <button type="button" onClick={e => this.props.onClick(e)} className={"button " + (this.props.selected ? 'selected' : '')}>
                {this.props.children}
            </button>
        )
    }

}
