import React from "react";
import BtnGroup from "../../../items/btn-group/btn-group";
import { getLocalStorageData, getGameEngineData } from "../../../../utils/utils";
import i18n from "../../../../utils/i18n";

let options = [
    { label: i18n.t('translations:steps:armRest:without'), value: "A", enabled: () => (getLocalStorageData().seatWidth.data !== undefined) ? (['A-L', 'A-R', 'B', 'C', 'D', 'E', 'F2', 'F3', 'G', 'H', 'I'].includes((getLocalStorageData() as any).seatWidth.data.width)) : false },
    { label: i18n.t('translations:steps:armRest:left'), value: "B", enabled: () => (getLocalStorageData().seatWidth.data !== undefined) ? (['A-L', 'A-R', 'B', 'C', 'D', 'E', 'F2', 'G', 'H', 'I'].includes((getLocalStorageData() as any).seatWidth.data.width)) : false },
    { label: i18n.t('translations:steps:armRest:right'), value: "C", enabled: () => (getLocalStorageData().seatWidth.data !== undefined) ? (['A-L', 'A-R', 'B', 'C', 'D', 'E', 'F2', 'G', 'H', 'I'].includes((getLocalStorageData() as any).seatWidth.data.width)) : false },
    { label: i18n.t('translations:steps:armRest:leftRight'), value: "D", enabled: () => (getLocalStorageData().seatWidth.data !== undefined) ? (['A-L', 'A-R', 'B', 'C', 'D', 'E', 'F2', 'G', 'H', 'I'].includes((getLocalStorageData() as any).seatWidth.data.width)) : false },
    { label: i18n.t('translations:steps:armRest:center'), value: "E", enabled: () => (getLocalStorageData().seatWidth.data !== undefined) ? (['B', 'C', 'D', 'E', 'F2', 'F3'].includes((getLocalStorageData() as any).seatWidth.data.width)) : false },
    { label: i18n.t('translations:steps:armRest:centerLeftRight'), value: "F", enabled:  () => (getLocalStorageData().seatWidth.data !== undefined) ? (['B', 'C', 'D', 'E', 'F2'].includes((getLocalStorageData() as any).seatWidth.data.width)) : false },
    { label: i18n.t('translations:steps:armRest:all'), value: "G", enabled:  () => (getLocalStorageData().seatWidth.data !== undefined) ? (['G', 'H', 'I'].includes((getLocalStorageData() as any).seatWidth.data.width)) : false }
]

interface ArmrestStates{
  armrest: string | null;
}

export default class Armrest extends React.Component<any,ArmrestStates> {

    constructor(props: any) {
        super(props)
        let data = getLocalStorageData()
        if (data.armrest.data) {
          this.state = {
            armrest: (data.armrest.data.armrest != null)? data.armrest.data.armrest : null
          }
        } else {
          this.state = {
            armrest: null
          }
        }
    }

    onChange(v: any) {
        let data = getLocalStorageData()
        if (!data.armrest.data) {
            data.armrest.data = {
                armrest: v
            }
        } else {
            data.armrest.data.armrest = v
        }

        let gData = getGameEngineData()

        this.setState({armrest: v})
        let isOnePlace = ["A-L", "A-R"].includes(data.seatWidth.data.width || "");
        let suffix = "";
        if (isOnePlace) {
            suffix = data.seatWidth.data.width == "A-L" ? "_dgauche" : "_ddroite";
        }
        let a : string;
        switch (v) {
            case "A":
                a = `sans${suffix}`
                break
            case "B":
                a = `gauche${suffix}`
                break
            case "C":
                a = `droite${suffix}`
                break
            case "D":
                a = `coter${suffix}`
                break
            case "E":
                a = "central"
                break
            case "F":
                a = "all"
                break
            case "G":
                a = "all"
                break
            default:
                a = "sans"
                break
        }
        gData.data.accoudoirs = a;
    }

    render() {
      return (
        <BtnGroup title={''} options={options} selectedOptionValue={this.state.armrest} onSelect={(v)=>this.onChange(v)}/>
      )
    }
}
