import React from "react";
import BtnGroup from "../../../items/btn-group/btn-group";
import { getGameEngineData, getLocalStorageData } from "../../../../utils/utils";
import i18n from "../../../../utils/i18n";

let seatWidthOnePlace = [
  { label: i18n.t('translations:steps:seatWidth:seatWidth:labelLeft', { count: 600 }), value: "A-L" },
  { label: i18n.t('translations:steps:seatWidth:seatWidth:labelRight', { count: 600 }), value: "A-R" }
]
let seatWidthTwoPlaces = [
  { label: i18n.t('translations:steps:seatWidth:seatWidth:label', { count: 860 }), value: "B" },
  { label: i18n.t('translations:steps:seatWidth:seatWidth:label', { count: 940 }), value: "C" },
  { label: i18n.t('translations:steps:seatWidth:seatWidth:label', { count: 1040 }), value: "D" },
  { label: i18n.t('translations:steps:seatWidth:seatWidth:label', { count: 1120 }), value: "E" },
  { label: i18n.t('translations:steps:seatWidth:seatWidth:label', { count: 1200 }), value: "F2" }
]
let seatWidthThreePlaces = [
  { label: i18n.t('translations:steps:seatWidth:seatWidth:label', { count: 1200 }), value: "F3" },
  { label: i18n.t('translations:steps:seatWidth:seatWidth:label', { count: 1290 }), value: "G" },
  { label: i18n.t('translations:steps:seatWidth:seatWidth:label', { count: 1400 }), value: "H" },
  { label: i18n.t('translations:steps:seatWidth:seatWidth:label', { count: 1500 }), value: "I" }
]
let places = [
  { label: i18n.t('translations:steps:seatWidth:seatNumber:label', { count: 1 }), value: 1 },
  { label: i18n.t('translations:steps:seatWidth:seatNumber:label', { count: 2 }), value: 2 },
  { label: i18n.t('translations:steps:seatWidth:seatNumber:label', { count: 3 }), value: 3 }
]

interface SeatWidthStates{
  seatsNumber: number,
  seatWidth: string
}
export default class SeatWidth extends React.Component<any, SeatWidthStates> {

    constructor(props: any) {
        super(props)
        let data = getLocalStorageData()
        if (data.seatWidth.data) {
          let seatWidth = data.seatWidth.data.width?data.seatWidth.data.width:"";
          this.state = {
            seatsNumber: (
              ["A-L", "A-R"].includes(seatWidth) ? 1 :
              ["B","C","D","E","F2"].includes(seatWidth) ? 2 :
              ["F3", "G","H","I"].includes(seatWidth)? 3 : 0
            ),
            seatWidth: (data.seatWidth.data.width !== null) ? data.seatWidth.data.width : ""
          }
        } else {
          this.state = {
            seatsNumber:0,
            seatWidth: ""
          }
        }
    }

    onSelectPlaces(n: number) {
      let data = getGameEngineData()
      data.name = `${n}p`
      let width
      switch (n){
        case 2:
          width = "86"
          break
        case 3:
          width = "129"
          break
        default:
          width = "60"
          break
      }
      data.data = {
        sdecs: "2dos",
        pieds: "neptune",
        assises: "assise",
        dossiers: "appuie_tete",
        accoudoirs: n === 1 ? "sans_dgauche" : "sans",
        widths: width,
        ceintures: "without",
      }
      this.setState({ seatsNumber: n })
    }

    onSelectSeatWidth(v: any) {
        let data = getLocalStorageData()
        if (!data.seatWidth.data) {
            data.seatWidth.data = {
                width: v
            }
        } else {
            data.seatWidth.data.width = v
        }
        let dataEngine = getGameEngineData()
        if (["A-L","A-R"].includes(v)) {
          dataEngine.data.accoudoirs = v === "A-L" ? "sans_dgauche" : "sans_ddroite"
          dataEngine.data.pieds = v === "A-L" ? "neptune_g" : "neptune_d"
          dataEngine.data.ceintures = v === "A-L" ? "gauche" : "droite"
        } else {
          dataEngine.data.ceintures = "without"
        }
        switch(v) {
          case "A-L":
          case "A-R":
            dataEngine.data.widths = "60"
            break
          case "B":
            dataEngine.data.widths = "86"
            break
          case "C":
            dataEngine.data.widths = "94"
            break
          case "D":
            dataEngine.data.widths = "104"
            break
          case "E":
            dataEngine.data.widths = "112"
            break
          case "F2":
          case "F3":
            dataEngine.data.widths = "120"
            break
          case "G":
            dataEngine.data.widths = "129"
            break
          case "H":
            dataEngine.data.widths = "140"
            break
          case "I":
            dataEngine.data.widths = "150"
            break
        }

        this.setState({ seatWidth: v })
    }

    render() {

        let seatWidths : any[] = []
        if(this.state.seatsNumber==1){ seatWidths=seatWidths.concat(seatWidthOnePlace);}
        if(this.state.seatsNumber==2){ seatWidths=seatWidths.concat(seatWidthTwoPlaces);}
        if(this.state.seatsNumber==3){ seatWidths=seatWidths.concat(seatWidthThreePlaces);}

        return (
          <div>
            <BtnGroup title={i18n.t('translations:steps:seatWidth:seatNumber:title')} options={places} selectedOptionValue={this.state.seatsNumber} onSelect={(v)=>this.onSelectPlaces(v!)}/>
            <BtnGroup title={i18n.t('translations:steps:seatWidth:seatWidth:title')} options={seatWidths} selectedOptionValue={this.state.seatWidth} onSelect={(v)=>this.onSelectSeatWidth(v)}/>
          </div>
        )
    }
}
