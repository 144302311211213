import { ParsedQuery } from "query-string";
import React, { useState } from "react";
import i18n from "../../../utils/i18n";

interface CodeFormProps {
    query : ParsedQuery<string>
}

export default function CodeForm ({ query } : CodeFormProps) {

  let [code, setCode] = useState<string>(query['code'] as string)

  return (
    <form action={window.location.search} method="GET">
        <input type="text" name="code" placeholder={i18n.t('enterConfigurationCode')} value={code} onChange={e => setCode(e.target.value)}/>
        <input type="hidden" className="form-control" name="revendeur" value={query['revendeur'] as string}/>
        <button type="submit" style={{display: "none"}}></button>
    </form>
  )
}