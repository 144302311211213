import React from "react";
import './step-container.scss';
import Button from "../../items/button/button";
import i18n from "../../../utils/i18n";
import BtnToggle from "../../items/btn-toggle/btn-toggle";
import { setCustomOptions } from "../../../utils/utils";

interface ButtonProps {
  showPrev : boolean,
  onPrevClick : () => void,
  showNext : boolean,
  onNextClick : () => void,
  showValid : boolean,
  onValidClick : () => void,
  onResetClick: () => void,
  title : string,
  showToggles: boolean
}

export default class StepContainer extends React.Component <ButtonProps, {toggle: boolean}> {
  constructor (props : ButtonProps) {
    super (props)

    this.state = {
      toggle: true
    }
  }

  render () {
    return (
      <div className={`step-container`}>
        {this.props.showToggles && <div className="toggles">
          <BtnToggle active={this.state.toggle} img={"assets/img/toggles/headrest"} toggle={(e) => {
            this.setState({
              toggle: !this.state.toggle
            })
            setCustomOptions(this.state.toggle)
          }}/>
          <div style={{height: '50px'}}>
            <Button onClick={this.props.onResetClick} selected={false}>{i18n.t('translations:restart')}</Button>
          </div>
        </div>}
        <div className="title">{this.props.title}</div>
        <div className="content">
          {this.props.children}
        </div>
        <div className="controls">
          {this.props.showPrev ? <Button selected={false} onClick={this.props.onPrevClick.bind(this)}>{i18n.t('previous')}</Button> : null}
          {this.props.showNext ? <Button selected={false} onClick={this.props.onNextClick.bind(this)}>{i18n.t('next')}</Button> : null}
          {this.props.showValid ? <Button selected={false} onClick={this.props.onValidClick.bind(this)}>{i18n.t('validate')}</Button> : null}
        </div>
      </div>
    )
  }
}
