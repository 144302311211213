import React, { createRef, useEffect, useState } from 'react'
import i18n from '../utils/i18n'
import SeatWidth from "../components/elements/steps/seat/seatWidth"
import SleepLength from "../components/elements/steps/seat/sleepLength"
import Armrest from "../components/elements/steps/headrest/armrest"
import ClothDistribution from "../components/elements/steps/colors/clothDistribution"
import Clothes from "../components/elements/steps/colors/clothes"
import {
  checkStepResponse,
  getFirstInvalidStepKeyOrLast,
  getGameEngineData,
  getIFrameWindow,
  getLocalStorageData,
  nextKey, prevKey, resetLocalStorageData, setCustomOptions
} from "../utils/utils";
import Errors from '../components/items/errors/errors'
import Breadcrumb from "../components/elements/breadcrumb/breadcrumb"
import StepContainer from "../components/elements/step-container/step-container"
import { generateCode } from '../utils/code_parser'
import jspdf from 'jspdf'
import { initEmpty } from '../utils/gameengine_data'

let generatedCode : string | null = null

const groups : {
  [key: string]: string
} = {
  vehicle : "assets/img/breadcrumb/picto-van.svg",
  seat : "assets/img/breadcrumb/picto-siege.svg",
  headrest : "assets/img/breadcrumb/picto_appui_tete.svg",
  more : "assets/img/breadcrumb/picto-plus.svg",
  colors : "assets/img/breadcrumb/picto-couleur.svg",
};

const steps : {
  [key: string]: {
    el: React.ReactElement,
    name: string,
    group: string
  }
} = {
  seatWidth:   { el: <SeatWidth/> , name: i18n.t('translations:steps:seatWidth:title'), group: "seat" },
  sleepLength:  { el: <SleepLength/> , name: i18n.t('translations:steps:sleepLength:title'), group: "seat" },

  armrest:     { el: <Armrest/> , name: i18n.t('translations:steps:armRest:title'), group: "headrest" },

  clothDist:   { el: <ClothDistribution/>, name: i18n.t('translations:steps:clothDist:title'), group: "colors" },
  clothes:     { el: <Clothes/>, name: i18n.t('translations:steps:clothes:title'), group: "colors" },
};

const Configurator =  () => {
  let [stepKey, setStepKey] = useState(getFirstInvalidStepKeyOrLast(steps));
  let [errors, setErrors] = useState<string[]>([]);
  const ref = createRef<HTMLDivElement>()

  useEffect(() => {
    getIFrameWindow().getItemsSelected = function () {
      return JSON.stringify(getGameEngineData())
    }

    let w : any = window

    w.setCommonOption = setCustomOptions
  }, []);

  const onNext = () => {
    let response = checkStepResponse(stepKey.key);
    if (response === true) {
      setStepKey(nextKey(steps, stepKey));
      setErrors([])
    } else {
      setErrors(response)
      let configuratorContent=document.querySelector('#configurator .step-container .content');
      if(configuratorContent){
        configuratorContent.scrollTo({top: 0,left: 0,behavior: 'smooth'});
      }
      return;
    }
  }

  const onPrevious = () => {
      if(prevKey(steps, stepKey) != stepKey) {
        setErrors([])
        setStepKey(prevKey(steps, stepKey))
      }
  }

  const onValid = async () => {
    let result = confirm("Souhaitez-vous télécharger le code de votre configuration ?");
    if (!result) return;
    let g = generateCode(getLocalStorageData(false))
    const pdf = new jspdf()
    let fontName = pdf.getFont().fontName
    pdf.addImage(document.getElementsByClassName('scopema-logo')![0]! as HTMLImageElement, 10, 10, 100, 25)
    pdf.setFont(fontName, "normal")
    pdf.setFontSize(12)
    pdf.text('Merci d\'avoir configuré votre banquette sur-mesure avec notre configurateur,', 10, 50)
    pdf.text('voici le code à transmettre à un revendeur pour avoir une proposition tarifaire :', 10, 55)
    pdf.text(g, 10, 65)
    pdf.text('Retrouvez tous nos revendeurs sur notre site :', 10, 80)
    pdf.text('https://scopema.com/le-reseau-de-revendeurs-scopema/', 10, 90, {})
    pdf.setFontSize(15)
    pdf.text('L\'équipe Scopema', 10, 105)
    pdf.setFontSize(12)
    pdf.text('https://scopema.com', 10, 113, {})
    let node = getComponentToScreen();
    let w = node.offsetWidth;
    let h = node.offsetHeight;
    let r = w / (pdf.internal.pageSize.width * 0.75);
    pdf.addImage(await node.toDataURL('image/jpeg'), 10, 125, w / r, h / r)
    pdf.save(`scopema_${(new Date()).toISOString()}.pdf`)
    // try {
    //   await navigator.clipboard.writeText(generatedCode)
    //   alert("Le code a été copié dans le presse papier, il vous suffit juste de le coller pour l'envoyer. Code : " + generatedCode)
    // } catch (e) {
    //   alert(`Le code n'a pas été copié dans le presse papier. Veuillez envoyez le code ${generatedCode} à Scopema`)
    // }
  }

  const onSelect = (key:string) => {
    if(checkStepResponse(key) === true || getFirstInvalidStepKeyOrLast(steps).key == key){
      setStepKey({key:key})
    }
  }

  const onReset = () => {
    resetLocalStorageData()
    setStepKey({key:'seatWidth'})
    let data = getGameEngineData()
    let empty = initEmpty()
    data.data = empty.data
    data.materials = empty.materials
    data.name = empty.name
  }

  const getComponentToScreen = () => {
    let node = getIFrameWindow().document.querySelector('#canvas') as HTMLCanvasElement | null
    if (!node) {
      throw new Error('You should provide correct html node.')
    }
    return node;
  };

  let selectedStep = steps[stepKey.key].el;

  let showPrev = prevKey(steps, stepKey).key != stepKey.key;
  let showNext = nextKey(steps, stepKey).key != stepKey.key;
  let showValid = nextKey(steps, stepKey).key == stepKey.key;

  return (
    <div id="main_configurator" ref={ref}>
      <div id="configurator_content">
        <div>
          <div id="breadcrumb-bar">
            <Breadcrumb steps={steps} stepKey={stepKey} onSelect={(key) => {onSelect(key)}} lastValid={getFirstInvalidStepKeyOrLast(steps)} groups={groups} />
            <img className={'scopema-logo'} src="./assets/img/Scopema-logo.png" alt="logo-scopema"/>
          </div>
          <div className="main_row">
            <div id="configurator">
              <StepContainer
                onResetClick={onReset}
                showToggles={true}
                showPrev={showPrev}
                onPrevClick={onPrevious}
                showNext={showNext}
                onNextClick={onNext}
                showValid={showValid}
                onValidClick={onValid}
                title={steps[stepKey.key].name}
              >
                <Errors errors={errors} />
                {selectedStep}
              </StepContainer>
            </div>
          </div>
        </div>
      </div>
      <iframe id="godot_render" src="godot.html"></iframe>
    </div>
  );
}

export default Configurator;