import React, { CSSProperties } from "react";
import './btn-group.scss'
import Button from "../button/button";
import ButtonImage from "../button-image/button-image";
import HelpButton from "../../elements/help-button/help-button";
import i18next from "i18next";
import TextField from "../textfield/textfield";

interface option {
  label:string,
  value:any,
  img?:string,
  enabled?:() => boolean,
}

interface BtnGroupProps<T> {
  title:string,
  helpPdfs?: string[],
  titleStyle?: CSSProperties,
  options:option[],
  selectedOptionValue:T|null,
  withOtherButton?:boolean,
  onSelect: (v:T|null)=>void,
}

export default class BtnGroup<T> extends React.Component <BtnGroupProps<T>, {}> {

    constructor (props : BtnGroupProps<T>) {
      super (props)
    }

    openHelp(url: string) {
      window.open(url, '_blank')
    }

    render() {
      let buttons = []
      for(let i=0; i<this.props.options.length; i++){
        let option = this.props.options[i];
        let imgButton = option.img ? true : false;
        let add = option.enabled ? option.enabled() : true;
        if (add) {
          if (imgButton) {
            buttons.push(<ButtonImage img={option.img || ''} key={option.value} onClick={() => this.props.onSelect(option.value)} selected={this.props.selectedOptionValue == option.value}>{option.label}</ButtonImage>)
          } else {
            buttons.push(<Button key={option.value} onClick={() => this.props.onSelect(option.value)} selected={this.props.selectedOptionValue == option.value}>{option.label}</Button>)
          }
        }
      }

      if (this.props.withOtherButton || false) {
        if (typeof this.props.selectedOptionValue === "string") {
          let otherLabel = `${i18next.t('other')}:`;
          let data = this.props.selectedOptionValue as string;
          let selected = data.startsWith(otherLabel);
          let value = data.indexOf(':') > 0 ? data.substring(data.indexOf(':') + 1) : '';
          buttons.push(<Button key={'other'} onClick={() => this.props.onSelect(otherLabel as any)} selected={selected}>{i18next.t('other')}</Button>)
          if (selected) {
            buttons.push(<TextField placeholder={otherLabel} value={value} onChange={(value) => this.props.onSelect(`${otherLabel}${value || ''}` as any)}/>)
          }
        }
      }
 
      let helpButtons = [];
      if (this.props.helpPdfs) {
        for (let i = 0; i < this.props.helpPdfs.length; i++) {
          let helpPdf = this.props.helpPdfs[i];
          helpButtons.push(<HelpButton key={helpPdf} onClick={() => this.openHelp(helpPdf)} size={15} marginLeft={10}/>)
        }
      }


      return (
        <div className="stepForm">
          {this.props.title && <h4 className="form-title" style={this.props.titleStyle}>{this.props.title}{helpButtons}</h4>}
          {buttons}
        </div>
      )
    }

}
