import i18next, { i18n } from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import en from "../translations/en"
import fr from "../translations/fr"
import es from "../translations/es"
import de from "../translations/de"
import { getLang } from "./route_parser"

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      fr: { translations: fr },
      en: { translations: en },
      es: { translations: es },
      de: { translations: de }
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    },
  });

export async function changeLang(i18next: i18n) {
  let lang = getLang()
  if (lang) {
    await i18next.changeLanguage(lang);
  }
}

changeLang(i18next)

export default i18next;
