import React from "react";
import './errors.scss'

interface ErrorsProps {
    errors: string[]
}

export default class Errors extends React.Component <ErrorsProps, {}> {

    constructor (props : ErrorsProps) {
        super (props)
    }


    render () {
        let elements = this.props.errors.map((v, i) => {
            return (<p className="" key={v}>{v}</p>)
        })
        return (
            <div className={'error-card'}>
                {elements}
            </div>
        )
    }
}
