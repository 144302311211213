import i18n from '../../../utils/i18n';
import {QuestionOption, QuestionsData} from './common';

export let constructorOptions: QuestionOption[] = [
  {
    label: i18n.t('translations:steps:constructor:brands:volkswagen'),
    value: i18n.t('translations:steps:constructor:brands:volkswagen')
  },
  {
    label: i18n.t('translations:steps:constructor:brands:renault'),
    value: i18n.t('translations:steps:constructor:brands:renault'),
  },
  {
    label: i18n.t('translations:steps:constructor:brands:peugeot'),
    value: i18n.t('translations:steps:constructor:brands:peugeot'),
  },
  {
    label: i18n.t('translations:steps:constructor:brands:citroen'),
    value: i18n.t('translations:steps:constructor:brands:citroen'),
  },
  {
    label: i18n.t('translations:steps:constructor:brands:fiat'),
    value: i18n.t('translations:steps:constructor:brands:fiat')
  },
  {
    label: i18n.t('translations:steps:constructor:brands:ford'),
    value: i18n.t('translations:steps:constructor:brands:ford')
  },
  {
    label: i18n.t('translations:steps:constructor:brands:opel'),
    value: i18n.t('translations:steps:constructor:brands:opel')
  },
  {
    label: i18n.t('translations:steps:constructor:brands:nissan'),
    value: i18n.t('translations:steps:constructor:brands:nissan')
  },
  {
    label: i18n.t('translations:steps:constructor:brands:toyota'),
    value: i18n.t('translations:steps:constructor:brands:toyota')
  },
  {
    label: i18n.t('translations:steps:constructor:brands:mercedes'),
    value: i18n.t('translations:steps:constructor:brands:mercedes')
  },
  {
    label: i18n.t('translations:steps:constructor:brands:man'),
    value: i18n.t('translations:steps:constructor:brands:man')
  },
]

export let typeOptionsEnabledVolswagen = (data: QuestionsData) => data?.constructor?.value == i18n.t('translations:steps:constructor:brands:volkswagen');
export let typeOptionsEnabledRenault = (data: QuestionsData) => data?.constructor?.value == i18n.t('translations:steps:constructor:brands:renault');
export let typeOptionsEnabledPeugeot = (data: QuestionsData) => data?.constructor?.value == i18n.t('translations:steps:constructor:brands:peugeot');
export let typeOptionsEnabledCitroen = (data: QuestionsData) => data?.constructor?.value == i18n.t('translations:steps:constructor:brands:citroen');
export let typeOptionsEnabledFiat = (data: QuestionsData) => data?.constructor?.value == i18n.t('translations:steps:constructor:brands:fiat');
export let typeOptionsEnabledFord = (data: QuestionsData) => data?.constructor?.value == i18n.t('translations:steps:constructor:brands:ford');
export let typeOptionsEnabledOpel = (data: QuestionsData) => data?.constructor?.value == i18n.t('translations:steps:constructor:brands:opel');
export let typeOptionsEnabledNissan = (data: QuestionsData) => data?.constructor?.value == i18n.t('translations:steps:constructor:brands:nissan');
export let typeOptionsEnabledToyota = (data: QuestionsData) => data?.constructor?.value == i18n.t('translations:steps:constructor:brands:toyota');
export let typeOptionsEnabledMercedes = (data: QuestionsData) => data?.constructor?.value == i18n.t('translations:steps:constructor:brands:mercedes');
export let typeOptionsEnabledMan = (data: QuestionsData) => data?.constructor?.value == i18n.t('translations:steps:constructor:brands:man');
export let typeOptions: QuestionOption[] = [

  {
    label: 'VWT4 - VOLKSWAGEN TRANSPORTER (1995-2003)',
    value: 'VWT4 - VOLKSWAGEN TRANSPORTER (1995-2003)',
    enabled: typeOptionsEnabledVolswagen
  },
  {
    label: 'VOLKSWAGEN CRAFTER (2006-2016)',
    value: 'VOLKSWAGEN CRAFTER (2006-2016)',
    enabled: typeOptionsEnabledVolswagen
  },
  {
    label: 'VWT5 - VOLKSWAGEN TRANSPORTER (2003-2017)',
    value: 'VWT5 - VOLKSWAGEN TRANSPORTER (2003-2017)',
    enabled: typeOptionsEnabledVolswagen
  },
  {
    label: 'VWT6 - VOLKSWAGEN TRANSPORTER (depuis 2017)',
    value: 'VWT6 - VOLKSWAGEN TRANSPORTER (depuis 2017)',
    enabled: typeOptionsEnabledVolswagen
  },
  {
    label: 'VOLKSWAGEN CRAFTER (depuis 2016)',
    value: 'VOLKSWAGEN CRAFTER (depuis 2016)',
    enabled: typeOptionsEnabledVolswagen
  },

  {
    label: 'X83 - RENAULT TRAFIC (2003-2014)',
    value: 'X83 - RENAULT TRAFIC (2003-2014)',
    enabled: typeOptionsEnabledRenault
  },
  {
    label: 'X62 - RENAULT MASTER (depuis 2010)',
    value: 'X62 - RENAULT MASTER (depuis 2010)',
    enabled: typeOptionsEnabledRenault
  },
  {label: 'X70 -RENAULT MASTER', value: 'X70 -RENAULT MASTER', enabled: typeOptionsEnabledRenault},
  {
    label: 'X82 - RENAULT TRAFIC (depuis 2014)',
    value: 'X82 - RENAULT TRAFIC (depuis 2014)',
    enabled: typeOptionsEnabledRenault
  },

  {
    label: 'X250 - PEUGEOT BOXER (depuis 2006)',
    value: 'X250 - PEUGEOT BOXER (depuis 2006)',
    enabled: typeOptionsEnabledPeugeot
  },
  {
    label: 'K0 - PEUGEOT EXPERT (depuis 2016)',
    value: 'K0 - PEUGEOT EXPERT (depuis 2016)',
    enabled: typeOptionsEnabledPeugeot
  },
  {
    label: 'G9 - PEUGEOT EXPERT (2007-2016)',
    value: 'G9 - PEUGEOT EXPERT (2007-2016)',
    enabled: typeOptionsEnabledPeugeot
  },

  {
    label: 'X250 - CITROEN JUMPER (depuis 2006)',
    value: 'X250 - CITROEN JUMPER (depuis 2006)',
    enabled: typeOptionsEnabledCitroen
  },
  {
    label: 'K0 - CITROEN JUMPY (depuis 2016)',
    value: 'K0 - CITROEN JUMPY (depuis 2016)',
    enabled: typeOptionsEnabledCitroen
  },
  {
    label: 'G9 - CITROEN JUMPY (2007-2016)',
    value: 'G9 - CITROEN JUMPY (2007-2016)',
    enabled: typeOptionsEnabledCitroen
  },

  {
    label: 'X250 - FIAT DUCATO (depuis 2006)',
    value: 'X250 - FIAT DUCATO (depuis 2006)',
    enabled: typeOptionsEnabledFiat
  },
  {
    label: 'X82 - FIAT TALENTO (depuis 2016)',
    value: 'X82 - FIAT TALENTO (depuis 2016)',
    enabled: typeOptionsEnabledFiat
  },
  {label: 'G9 - FIAT SCUDO (2007-2016)', value: 'G9 - FIAT SCUDO (2007-2016)', enabled: typeOptionsEnabledFiat},

  {
    label: 'V362 - FORD TRANSIT CUSTOM (de 2013 à 2023)',
    value: 'V362 - FORD TRANSIT CUSTOM (de 2013 à 2023)',
    enabled: typeOptionsEnabledFord
  },
  {
    label: 'V710 - FORD TRANSIT CUSTOM (depuis 2023)',
    value: 'V710 - FORD TRANSIT CUSTOM (depuis 2023)',
    enabled: typeOptionsEnabledFord
  },
  {
    label: 'TRANSIT - FORD TRANSIT MK10 (2000-2014)',
    value: 'TRANSIT - FORD TRANSIT MK10 (2000-2014)',
    enabled: typeOptionsEnabledFord
  },
  {
    label: 'V363 - FORD TRANSIT (depuis 2014)',
    value: 'V363 - FORD TRANSIT (depuis 2014)',
    enabled: typeOptionsEnabledFord
  },

  {
    label: 'K0 - OPEL VAUXHALL VIVARO-ZAFIRA LIFE (depuis 2019)',
    value: 'K0 - OPEL VAUXHALL VIVARO-ZAFIRA LIFE (depuis 2019)',
    enabled: typeOptionsEnabledOpel
  },
  {
    label: 'X82 - OPEL VAUXHALL VIVARO (2014-2019)',
    value: 'X82 - OPEL VAUXHALL VIVARO (2014-2019)',
    enabled: typeOptionsEnabledOpel
  },
  {
    label: 'X83 - OPEL VAUXHALL VIVARO (2003-2014)',
    value: 'X83 - OPEL VAUXHALL VIVARO (2003-2014)',
    enabled: typeOptionsEnabledOpel
  },
  {
    label: 'X70 - OPEL VAUXHALL MOVANO (1998-2009)',
    value: 'X70 - OPEL VAUXHALL MOVANO (1998-2009)',
    enabled: typeOptionsEnabledOpel
  },
  {
    label: 'X62 - OPEL VAUXHALL MOVANO (depuis 2010)',
    value: 'X62 - OPEL VAUXHALL MOVANO (depuis 2010)',
    enabled: typeOptionsEnabledOpel
  },

  {
    label: 'X83 - NISSAN PRIMASTAR (2003-2014)',
    value: 'X83 - NISSAN PRIMASTAR (2003-2014)',
    enabled: typeOptionsEnabledNissan
  },
  {
    label: 'X70 - NISSAN INTERSTAR (1998-2009)',
    value: 'X70 - NISSAN INTERSTAR (1998-2009)',
    enabled: typeOptionsEnabledNissan
  },
  {
    label: 'X82 - NISSAN NV300 (depuis 2014)',
    value: 'X82 - NISSAN NV300 (depuis 2014)',
    enabled: typeOptionsEnabledNissan
  },
  {label: 'NV200 - NISSAN (depuis 2012)', value: 'NV200 - NISSAN (depuis 2012)', enabled: typeOptionsEnabledNissan},
  {
    label: 'X62 - NISSAN NV400 (depuis 2010)',
    value: 'X62 - NISSAN NV400 (depuis 2010)',
    enabled: typeOptionsEnabledNissan
  },

  {
    label: 'K0 - TOYOTA PROACE (depuis 2016)',
    value: 'K0 - TOYOTA PROACE (depuis 2016)',
    enabled: typeOptionsEnabledToyota
  },
  {label: 'G9 - TOYOTA PROACE (2007-2016)', value: 'G9 - TOYOTA PROACE (2007-2016)', enabled: typeOptionsEnabledToyota},

  {
    label: 'W447 - MERCEDES VITO (depuis 2015)',
    value: 'W447 - MERCEDES VITO (depuis 2015)',
    enabled: typeOptionsEnabledMercedes
  },
  {
    label: 'W639 - MERCEDES VITO VIANO (2004-2015)',
    value: 'W639 - MERCEDES VITO VIANO (2004-2015)',
    enabled: typeOptionsEnabledMercedes
  },
  {
    label: 'W638 - MERCEDES VITO (1996-2003)',
    value: 'W638 - MERCEDES VITO (1996-2003)',
    enabled: typeOptionsEnabledMercedes
  },
  {
    label: 'M907-M910 - MERCEDES SPRINTER (depuis 2018)',
    value: 'M907-M910 - MERCEDES SPRINTER (depuis 2018)',
    enabled: typeOptionsEnabledMercedes
  },
  {
    label: 'M906 - MERCEDES SPRINTER (2006-2018)',
    value: 'M906 - MERCEDES SPRINTER (2006-2018)',
    enabled: typeOptionsEnabledMercedes
  },

  {label: 'MAN TGE (depuis 2016)', value: 'MAN TGE (depuis 2016)', enabled: typeOptionsEnabledMan},
]

let drivePdfs = (_: QuestionsData) => ['/assets/pdfs/help/position_de_conduite.pdf'];
export let driveOptions: QuestionOption[] = [
  {label: i18n.t('left') + ' (FR)', value: i18n.t('left'), helpPdfs: drivePdfs},
  {label: i18n.t('right') + ' (GB)', value: i18n.t('right'), helpPdfs: drivePdfs},
]

let interfaceHelpPdfs = (_: QuestionsData) => ['/assets/pdfs/help/interface.pdf']
export let interfaceOptions: QuestionOption[] = [
  {
    label: i18n.t('yes'), value: i18n.t('yes'), helpPdfs: interfaceHelpPdfs, enabled: (data: QuestionsData) => ![
      // Enable if is not value at below
      'VWT4 - VOLKSWAGEN TRANSPORTER (1995-2003)',
      'V363 - FORD TRANSIT (depuis 2014)',
      'TRANSIT - FORD TRANSIT MK10 (2000-2014)',
      'MAN TGE (depuis 2016)',
      'VOLKSWAGEN CRAFTER (depuis 2016)'
    ].includes(data?.type?.value)  // ISSUE #6338
  },
  {label: i18n.t('no'), value: i18n.t('no'), helpPdfs: interfaceHelpPdfs}
]

export let vehiculeOptionEnabled = (data: QuestionsData) => [i18n.t('yes')].includes(data?.interface?.value || '');
export let vehiculeOptions: QuestionOption[] = [
  {label: 'L1', value: 'L1', enabled: vehiculeOptionEnabled},
  {label: 'L2', value: 'L2', enabled: vehiculeOptionEnabled},
  {
    label: 'L3', value: 'L3', enabled: (data: QuestionsData) => vehiculeOptionEnabled(data) &&
      ![
        // Enable if is not value at below
        'V710 - FORD TRANSIT CUSTOM (depuis 2023)'
      ].includes(data?.type?.value)
  }
]

let implementationPdfs = (_: QuestionsData) => ['/assets/pdfs/help/position_bq.pdf'];
export let implementationOptions: QuestionOption[] = [
  {
    label: i18n.t('translations:steps:benchseatPosition:maxiFront'),
    value: i18n.t('translations:steps:benchseatPosition:maxiFront'),
    helpPdfs: implementationPdfs
  },
  {
    label: i18n.t('translations:steps:benchseatPosition:maxiRear'),
    value: i18n.t('translations:steps:benchseatPosition:maxiRear'),
    helpPdfs: implementationPdfs
  },
  {
    label: i18n.t('translations:steps:benchseatPosition:other'),
    value: i18n.t('translations:steps:benchseatPosition:other'),
    helpPdfs: implementationPdfs
  },
]

export let bedHeightOptions: QuestionOption[] = [
  {label: '620', value: '620'}
]

export let seatWidthOptions: QuestionOption[] = [
  {label: '060 - 1P', value: '060 - 1P', godotValues: ['A-L', 'A-R']},
  {label: '086 - 2P', value: '086 - 2P', godotValues: ['B']},
  {label: '094 - 2P', value: '094 - 2P', godotValues: ['C']},
  {label: '104 - 2P', value: '104 - 2P', godotValues: ['D']},
  {label: '112 - 2P', value: '112 - 2P', godotValues: ['E']},
  {label: '120 - 2P', value: '120 - 2P', godotValues: ['F2']},
  {label: '120 - 3P', value: '120 - 3P', godotValues: ['F3']},
  {label: '129 - 3P', value: '129 - 3P', godotValues: ['G']},
  {label: '140 - 3P', value: '140 - 3P', godotValues: ['H']},
  {label: '150 - 3P', value: '150 - 3P', godotValues: ['I']}
]

let handlePadLeftHelpPdfs = (_: QuestionsData) => ['/assets/pdfs/help/pdad_a_gauche_gb.pdf']
let handlePadRightHelpPdfs = (_: QuestionsData) => ['/assets/pdfs/help/pdad_a_droite_ue.pdf']
let typeOptionsHandlePadForLeftDriveOption = (data: QuestionsData) => data?.drive?.value == i18n.t('right');
let typeOptionsHandlePadForRightDriveOption = (data: QuestionsData) => data?.drive?.value == i18n.t('left');
export let handlePadOptions: QuestionOption[] = [
  {
    label: i18n.t('translations:steps:handlePad:handleAxleLeft'),
    value: i18n.t('translations:steps:handlePad:handleAxleLeft'),
    helpPdfs: handlePadLeftHelpPdfs,
    enabled: (data: QuestionsData) => typeOptionsHandlePadForLeftDriveOption(data) && !['060 - 1P'].includes(data?.seatWidth?.value || '')
  },
  {
    label: i18n.t('translations:steps:handlePad:handleAxleRight'),
    value: i18n.t('translations:steps:handlePad:handleAxleRight'),
    helpPdfs: handlePadRightHelpPdfs,
    enabled: (data: QuestionsData) => typeOptionsHandlePadForRightDriveOption(data) && !['060 - 1P'].includes(data?.seatWidth?.value || '')
  },
  {
    label: i18n.t('translations:steps:handlePad:integratedAxleLeft'),
    value: i18n.t('translations:steps:handlePad:integratedAxleLeft'),
    helpPdfs: handlePadLeftHelpPdfs,
    enabled: (data: QuestionsData) => typeOptionsHandlePadForLeftDriveOption(data) && !['150 - 3P'].includes(data?.seatWidth?.value || '')
  },
  {
    label: i18n.t('translations:steps:handlePad:integratedAxleRight'),
    value: i18n.t('translations:steps:handlePad:integratedAxleRight'),
    helpPdfs: handlePadRightHelpPdfs,
    enabled: (data: QuestionsData) => typeOptionsHandlePadForRightDriveOption(data) && !['150 - 3P'].includes(data?.seatWidth?.value || '')
  },
  {
    label: i18n.t('translations:steps:handlePad:handleAxlePdadLeft', {count: 90}),
    value: i18n.t('translations:steps:handlePad:handleAxlePdadLeft', {count: 90}),
    helpPdfs: handlePadLeftHelpPdfs,
    enabled: (data: QuestionsData) => typeOptionsHandlePadForLeftDriveOption(data) && !['060 - 1P'].includes(data?.seatWidth?.value || '')
  },
  {
    label: i18n.t('translations:steps:handlePad:handleAxlePdadRight', {count: 90}),
    value: i18n.t('translations:steps:handlePad:handleAxlePdadRight', {count: 90}),
    helpPdfs: handlePadRightHelpPdfs,
    enabled: (data: QuestionsData) => typeOptionsHandlePadForRightDriveOption(data) && !['060 - 1P'].includes(data?.seatWidth?.value || '')
  },
  {
    label: i18n.t('translations:steps:handlePad:handleAxlePdadLeft', {count: 105}),
    value: i18n.t('translations:steps:handlePad:handleAxlePdadLeft', {count: 105}),
    helpPdfs: handlePadLeftHelpPdfs,
    enabled: (data: QuestionsData) => typeOptionsHandlePadForLeftDriveOption(data) && !['060 - 1P'].includes(data?.seatWidth?.value || '')
  },
  {
    label: i18n.t('translations:steps:handlePad:handleAxlePdadRight', {count: 105}),
    value: i18n.t('translations:steps:handlePad:handleAxlePdadRight', {count: 105}),
    helpPdfs: handlePadRightHelpPdfs,
    enabled: (data: QuestionsData) => typeOptionsHandlePadForRightDriveOption(data) && !['060 - 1P'].includes(data?.seatWidth?.value || '')
  },
  {
    label: i18n.t('translations:steps:handlePad:handleAxlePdadLeft', {count: 125}),
    value: i18n.t('translations:steps:handlePad:handleAxlePdadLeft', {count: 125}),
    helpPdfs: handlePadLeftHelpPdfs,
    enabled: (data: QuestionsData) => typeOptionsHandlePadForLeftDriveOption(data) && !['060 - 1P'].includes(data?.seatWidth?.value || '')
  },
  {
    label: i18n.t('translations:steps:handlePad:handleAxlePdadRight', {count: 125}),
    value: i18n.t('translations:steps:handlePad:handleAxlePdadRight', {count: 125}),
    helpPdfs: handlePadRightHelpPdfs,
    enabled: (data: QuestionsData) => typeOptionsHandlePadForRightDriveOption(data) && !['060 - 1P'].includes(data?.seatWidth?.value || '')
  },
  {
    label: i18n.t('translations:steps:handlePad:handleAxlePdadLeft', {count: 140}),
    value: i18n.t('translations:steps:handlePad:handleAxlePdadLeft', {count: 140}),
    helpPdfs: handlePadLeftHelpPdfs,
    enabled: (data: QuestionsData) => typeOptionsHandlePadForLeftDriveOption(data) && !['060 - 1P'].includes(data?.seatWidth?.value || '')
  },
  {
    label: i18n.t('translations:steps:handlePad:handleAxlePdadRight', {count: 140}),
    value: i18n.t('translations:steps:handlePad:handleAxlePdadRight', {count: 140}),
    helpPdfs: handlePadRightHelpPdfs,
    enabled: (data: QuestionsData) => typeOptionsHandlePadForRightDriveOption(data) && !['060 - 1P'].includes(data?.seatWidth?.value || '')
  },
]

export let sleepLengthOptions: QuestionOption[] = [
  {label: i18n.t('translations:steps:sleepLength:labelNeptune', {count: 1210}), value: '121 N3P', godotValues: ['1']},
  {
    label: i18n.t('translations:steps:sleepLength:labelAltair', {count: 1790}),
    value: '179 A3P dos58',
    godotValues: ['2']
  },
  {
    label: i18n.t('translations:steps:sleepLength:labelAltair', {count: 1860}),
    value: '186 A3P dos65',
    godotValues: ['3']
  },
  {
    label: i18n.t('translations:steps:sleepLength:labelAltair', {count: 1940}),
    value: '194 A3P dos73',
    godotValues: ['4']
  }
]

let sdecValidator = (data: QuestionsData) => ![
  i18n.t('translations:steps:handlePad:integratedAxleLeft'),
  i18n.t('translations:steps:handlePad:integratedAxleRight')
].includes(data?.handlePad?.value || '');
let sdecHelpPdfs = (_: QuestionsData) => ['/assets/pdfs/help/sdec.pdf']
export let sdecOptions: QuestionOption[] = [
  {label: i18n.t('translations:steps:sdecs:values:without'), value: 'Ø', helpPdfs: sdecHelpPdfs},
  {
    label: i18n.t('translations:steps:sdecs:values:80_X_100'),
    value: '80x100',
    enabled: (data: QuestionsData) => sdecValidator(data) && [
      'X82 - RENAULT TRAFIC (depuis 2014)',
      'X83 - RENAULT TRAFIC (2003-2014)',
      'K0 - OPEL VAUXHALL VIVARO-ZAFIRA LIFE (depuis 2019)',
      'X82 - OPEL VAUXHALL VIVARO (2014-2019)',
      'X83 - OPEL VAUXHALL VIVARO (2003-2014)',
      'X82 - NISSAN NV300 (depuis 2014)',
      'X83 - NISSAN PRIMASTAR (2003-2014)',
      'X82 - FIAT TALENTO (depuis 2016)'
    ].includes(data?.type?.value || ''),
    helpPdfs: sdecHelpPdfs
  },
  {
    label: i18n.t('translations:steps:sdecs:values:90_X_100'),
    value: '90x100',
    enabled: (data: QuestionsData) => sdecValidator(data) && [
      'VWT4 - VOLKSWAGEN TRANSPORTER (1995-2003)',
      'G9 - CITROEN JUMPY (2007-2016)',
      'G9 - FIAT SCUDO (2007-2016)',
      'G9 - TOYOTA PROACE (2007-2016)'
    ].includes(data?.type?.value || ''),
    helpPdfs: sdecHelpPdfs
  },
  {
    label: i18n.t('translations:steps:sdecs:values:90_X_140'),
    value: '90x140',
    enabled: (data: QuestionsData) => sdecValidator(data) && [
      'K0 - PEUGEOT EXPERT (depuis 2016)',
      'G9 - CITROEN JUMPY (2007-2016)',
      'G9 - FIAT SCUDO (2007-2016)',
      'K0 - TOYOTA PROACE (depuis 2016)'
    ].includes(data?.type?.value || ''),
    helpPdfs: sdecHelpPdfs
  },
  {
    label: i18n.t('translations:steps:sdecs:values:100_X_200'),
    value: '100x200',
    enabled: (data: QuestionsData) => sdecValidator(data) && [
      'V710 - FORD TRANSIT CUSTOM (depuis 2023)'
    ].includes(data?.type?.value || ''),
    helpPdfs: sdecHelpPdfs
  },
  {
    label: i18n.t('translations:steps:sdecs:values:140_X_140'),
    value: '140x140',
    enabled: (data: QuestionsData) => sdecValidator(data) && [
      'VWT5 - VOLKSWAGEN TRANSPORTER (2003-2017)',
      'VWT6 - VOLKSWAGEN TRANSPORTER (depuis 2017)',
      'W639 - MERCEDES VITO VIANO (2004-2015)',
      'W638 - MERCEDES VITO (1996-2003)',
      'W447 - MERCEDES VITO (depuis 2015)'
    ].includes(data?.type?.value || ''),
    helpPdfs: sdecHelpPdfs
  },
  {
    label: i18n.t('translations:steps:sdecs:values:140_X_200'),
    value: '140x200',
    enabled: (data: QuestionsData) => sdecValidator(data) && [
      'V362 - FORD TRANSIT CUSTOM (de 2013 à 2023)'
    ].includes(data?.type?.value || ''),
    helpPdfs: sdecHelpPdfs
  },
]

export let armrestOptions: QuestionOption[] = [
  {
    label: i18n.t('translations:steps:armRest:without'),
    value: i18n.t('translations:steps:armRest:without'),
    godotValues: ['A']
  },
  {
    label: i18n.t('translations:steps:armRest:left'),
    value: i18n.t('translations:steps:armRest:left'),
    godotValues: ['B'],
    enabled: (data: QuestionsData) => !['120 - 3P'].includes(data?.seatWidth?.value || '')
  },
  {
    label: i18n.t('translations:steps:armRest:right'),
    value: i18n.t('translations:steps:armRest:right'),
    godotValues: ['C'],
    enabled: (data: QuestionsData) => !['120 - 3P'].includes(data?.seatWidth?.value || '')
  },
  {
    label: i18n.t('translations:steps:armRest:leftRight'),
    value: i18n.t('translations:steps:armRest:leftRight'),
    godotValues: ['D'],
    enabled: (data: QuestionsData) => !['120 - 3P'].includes(data?.seatWidth?.value || '')
  },
  {
    label: i18n.t('translations:steps:armRest:center'),
    value: i18n.t('translations:steps:armRest:center'),
    godotValues: ['E'],
    enabled: (data: QuestionsData) => !['060 - 1P', '129 - 3P', '140 - 3P', '150 - 3P'].includes(data?.seatWidth?.value || '')
  },
  {
    label: i18n.t('translations:steps:armRest:centerLeftRight'),
    value: i18n.t('translations:steps:armRest:centerLeftRight'),
    godotValues: ['F'],
    enabled: (data: QuestionsData) => !['060 - 1P', '120 - 3P', '129 - 3P', '140 - 3P', '150 - 3P'].includes(data?.seatWidth?.value || '')
  },
  {
    label: i18n.t('translations:steps:armRest:all'),
    value: i18n.t('translations:steps:armRest:all'),
    godotValues: ['G'],
    enabled: (data: QuestionsData) => !['060 - 1P', '086 - 2P', '094 - 2P', '104 - 2P', '112 - 2P', '120 - 2P', '120 - 3P'].includes(data?.seatWidth?.value || '')
  }
]

let thermoflexPdfs = (_: QuestionsData) => ['/assets/pdfs/help/surmatelas_3d.pdf'];
export let thermoflexOptions: QuestionOption[] = [
  {label: i18n.t('without'), value: 'Ø', helpPdfs: thermoflexPdfs},
  {label: i18n.t('with'), value: 'thermo', helpPdfs: thermoflexPdfs},
]

let isofixHelpPdfs = (_: QuestionsData) => ['/assets/pdfs/help/isofix.pdf']
export let isofixOptions: QuestionOption[] = [
  // {label: i18n.t('without'), value: 'Ø', helpPdfs: isofixHelpPdfs},
  {label: i18n.t('with'), value: 'isofix', helpPdfs: isofixHelpPdfs},
]

export let clothesDistributionOptions: QuestionOption[] = [
  {
    label: i18n.t('translations:steps:clothDist:without'),
    value: i18n.t('translations:steps:clothDist:without'),
    godotValues: ['A']
  },
  {
    label: i18n.t('translations:steps:clothDist:one'),
    value: i18n.t('translations:steps:clothDist:one'),
    godotValues: ['B']
  },
  {
    label: i18n.t('translations:steps:clothDist:two'),
    value: i18n.t('translations:steps:clothDist:two'),
    godotValues: ['C']
  },
]

let ribFilter = (value: string) => [
  'safariBlack',
  'draduro7001', 'draduro8009', 'draduro8032', 'draduro8212', 'draduro8335', 'draduro8901', 'draduro8915',
  'enduro470', 'enduro1418', 'enduro1677', 'enduro2687', 'enduro7005', 'enduro8002', 'enduro8015', 'enduro8440',
  'enduro8504', 'enduro8514', 'enduro8800', 'enduro8841', 'enduro8923', 'resistRosa', 'resistAzaleaBlack'
].includes(value)
let fordOptionsFilter = (value: string) => [
  'forduni'
].includes(value)
let mercedesOptionsFilter = (value: string) => [
  'mercedestunja',
  'mercedestunjauni',
  'limamotif',
  'limauni'
].includes(value)
let opelOptionsFilter = (value: string) => [
  'o-vivaroconnectm',
  'o-vivaroconnectu'
].includes(value)
let psaOptionsFilter = (value: string) => [
  'peugeotboxer',
  'psakobrasilia',
  'psakocuritiba',
  'fiatscudo',
  'scudobleuuni',
  'f-ducato2014m',
  'f-ducato2014u',
].includes(value)
let renaultOptionsFilter = (value: string) => [
  'renaultdino',
  'renaultx82java',
  'renaultkario',
  'kompo'
].includes(value)
let volkswagenOptionsFilter = (value: string) => [
  't5boxuni',
  'tasamouni',
  'vwaustin',
  'vwbricks',
  'vwdoublegrid',
  'vwt4inca',
  'vwt5incaii',
  'vwt5place',
  'vwt6simora',
  'titane',
].includes(value)
export let clothesOptions: QuestionOption[] = [
  {
    label: i18n.t('translations:steps:clothes:textures:safariBlack'),
    value: 'safariBlack',
    img: './assets/img/textures/renaultkario.jpg',
    godotValues: ['safariBlack']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:draduro7001'),
    value: 'draduro7001',
    img: './assets/img/textures/draduro7001.jpg',
    godotValues: ['draduro7001']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:draduro8009'),
    value: 'draduro8009',
    img: './assets/img/textures/draduro8009.jpg',
    godotValues: ['draduro8009']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:draduro8032'),
    value: 'draduro8032',
    img: './assets/img/textures/draduro8032.jpg',
    godotValues: ['draduro8032']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:draduro8212'),
    value: 'draduro8212',
    img: './assets/img/textures/draduro8212.jpg',
    godotValues: ['draduro8212']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:draduro8335'),
    value: 'draduro8335',
    img: './assets/img/textures/draduro8335.jpg',
    godotValues: ['draduro8335']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:draduro8901'),
    value: 'draduro8901',
    img: './assets/img/textures/draduro8901.jpg',
    godotValues: ['draduro8901']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:draduro8915'),
    value: 'draduro8915',
    img: './assets/img/textures/draduro8915.jpg',
    godotValues: ['draduro8915']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:enduro470'),
    value: 'enduro470',
    img: './assets/img/textures/enduro470.jpg',
    godotValues: ['enduro470']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:enduro1478'),
    value: 'enduro1478',
    img: './assets/img/textures/enduro1478.jpg',
    godotValues: ['enduro1478']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:enduro1677'),
    value: 'enduro1677',
    img: './assets/img/textures/enduro1677.jpg',
    godotValues: ['enduro1677']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:enduro2687'),
    value: 'enduro2687',
    img: './assets/img/textures/enduro2687.jpg',
    godotValues: ['enduro2687']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:enduro7005'),
    value: 'enduro7005',
    img: './assets/img/textures/enduro7005.jpg',
    godotValues: ['enduro7005']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:enduro8015'),
    value: 'enduro8015',
    img: './assets/img/textures/enduro8015.jpg',
    godotValues: ['enduro8015']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:enduro8440'),
    value: 'enduro8440',
    img: './assets/img/textures/enduro8440.jpg',
    godotValues: ['enduro8440']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:enduro8504'),
    value: 'enduro8504',
    img: './assets/img/textures/enduro8504.jpg',
    godotValues: ['enduro8504']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:enduro8514'),
    value: 'enduro8514',
    img: './assets/img/textures/enduro8514.jpg',
    godotValues: ['enduro8514']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:enduro8800'),
    value: 'enduro8800',
    img: './assets/img/textures/enduro8800.jpg',
    godotValues: ['enduro8800']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:enduro8841'),
    value: 'enduro8841',
    img: './assets/img/textures/enduro8841.jpg',
    godotValues: ['enduro8841']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:enduro8923'),
    value: 'enduro8923',
    img: './assets/img/textures/enduro8923.jpg',
    godotValues: ['enduro8923']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:resistRosa'),
    value: 'resistRosa',
    img: './assets/img/textures/resistRosa.jpg',
    godotValues: ['resistRosa']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:resistAzaleaBlack'),
    value: 'resistAzaleaBlack',
    img: './assets/img/textures/resistAzaleaBlack.jpg',
    godotValues: ['resistAzaleaBlack']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:limamotif'),
    value: 'limamotif',
    img: './assets/img/textures/limamotif.jpg',
    godotValues: ['limamotif']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:limauni'),
    value: 'limauni',
    img: './assets/img/textures/limauni.jpg',
    godotValues: ['limauni']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:black'),
    value: 'noir',
    img: './assets/img/textures/noir.jpg',
    godotValues: ['noir']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:titane'),
    value: 'titane',
    img: './assets/img/textures/titane.jpg',
    godotValues: ['titane']
  },
  // { label: i18n.t('translations:steps:clothes:textures:fTransitcustom'), value: 'f-transitcustom', img: './assets/img/textures/f-transitcustom.jpg', godotValues: ['f-transitcustom'] },
  {
    label: i18n.t('translations:steps:clothes:textures:forduni'),
    value: 'forduni',
    img: './assets/img/textures/forduni.jpg',
    godotValues: ['forduni']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:mercedestunja'),
    value: 'mercedestunja',
    img: './assets/img/textures/mercedestunja.jpg',
    godotValues: ['mercedestunja']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:mercedestunjauni'),
    value: 'mercedestunjauni',
    img: './assets/img/textures/mercedestunjauni.jpg',
    godotValues: ['mercedestunjauni']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:oVivaroconnectm'),
    value: 'o-vivaroconnectm',
    img: './assets/img/textures/o-vivaroconnectm.jpg',
    godotValues: ['o-vivaroconnectm']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:oVivaroconnectu'),
    value: 'o-vivaroconnectu',
    img: './assets/img/textures/o-vivaroconnectu.jpg',
    godotValues: ['o-vivaroconnectu']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:peugeotboxer'),
    value: 'peugeotboxer',
    img: './assets/img/textures/peugeotboxer.jpg',
    godotValues: ['peugeotboxer']
  }, // Peugeot
  {
    label: i18n.t('translations:steps:clothes:textures:psakobrasilia'),
    value: 'psakobrasilia',
    img: './assets/img/textures/psakobrasilia.jpg',
    godotValues: ['psakobrasilia']
  }, // Peugeot
  {
    label: i18n.t('translations:steps:clothes:textures:psakocuritiba'),
    value: 'psakocuritiba',
    img: './assets/img/textures/psakocuritiba.jpg',
    godotValues: ['psakocuritiba']
  }, // Peugeot
  {
    label: i18n.t('translations:steps:clothes:textures:fiatscudo'),
    value: 'fiatscudo',
    img: './assets/img/textures/fiatscudo.jpg',
    godotValues: ['fiatscudo']
  }, // Fiat
  {
    label: i18n.t('translations:steps:clothes:textures:scudobleuuni'),
    value: 'scudobleuuni',
    img: './assets/img/textures/scudobleuuni.jpg',
    godotValues: ['scudobleuuni']
  }, // Fiat
  {
    label: i18n.t('translations:steps:clothes:textures:fDucato2014m'),
    value: 'f-ducato2014m',
    img: './assets/img/textures/f-ducato2014m.jpg',
    godotValues: ['f-ducato2014m']
  }, // Ducato -> Fiat
  {
    label: i18n.t('translations:steps:clothes:textures:fDucato2014u'),
    value: 'f-ducato2014u',
    img: './assets/img/textures/f-ducato2014u.jpg',
    godotValues: ['f-ducato2014u']
  }, // Ducato -> Fiat
  {
    label: i18n.t('translations:steps:clothes:textures:renaultdino'),
    value: 'renaultdino',
    img: './assets/img/textures/renaultdino.jpg',
    godotValues: ['renaultdino']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:renaultkario'),
    value: 'renaultkario',
    img: './assets/img/textures/renaultkario.jpg',
    godotValues: ['renaultkario']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:renaultx82java'),
    value: 'renaultx82java',
    img: './assets/img/textures/renaultx82java.jpg',
    godotValues: ['renaultx82java']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:t5boxuni'),
    value: 't5boxuni',
    img: './assets/img/textures/t5boxuni.jpg',
    godotValues: ['t5boxuni']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:tasamouni'),
    value: 'tasamouni',
    img: './assets/img/textures/tasamouni.jpg',
    godotValues: ['tasamouni']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:vwaustin'),
    value: 'vwaustin',
    img: './assets/img/textures/vwaustin.jpg',
    godotValues: ['vwaustin']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:vwbricks'),
    value: 'vwbricks',
    img: './assets/img/textures/vwbricks.jpg',
    godotValues: ['vwbricks']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:vwdoublegrid'),
    value: 'vwdoublegrid',
    img: './assets/img/textures/vwdoublegrid.jpg',
    godotValues: ['vwdoublegrid']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:vwt4inca'),
    value: 'vwt4inca',
    img: './assets/img/textures/vwt4inca.jpg',
    godotValues: ['vwt4inca']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:vwt5incaii'),
    value: 'vwt5incaii',
    img: './assets/img/textures/vwt5incaii.jpg',
    godotValues: ['vwt5incaii']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:vwt5place'),
    value: 'vwt5place',
    img: './assets/img/textures/vwt5place.jpg',
    godotValues: ['vwt5place']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:vwt6simora'),
    value: 'vwt6simora',
    img: './assets/img/textures/vwt6simora.jpg',
    godotValues: ['vwt6simora']
  },
  {
    label: i18n.t('translations:steps:clothes:textures:kompo'),
    value: 'kompo',
    img: './assets/img/textures/kompo.jpg',
    godotValues: ['kompo']
  },
]
export let clothes2Options = clothesOptions.map(option => {
  let callback: (data: QuestionsData) => boolean;

  if (ribFilter(option.value)) {
    callback = (_: QuestionsData) => true;
  } else if (fordOptionsFilter(option.value)) {
    callback = (data: QuestionsData) => fordOptionsFilter(data.clothes1.value || '') || ribFilter(data.clothes1.value || '');
  } else if (mercedesOptionsFilter(option.value)) {
    callback = (data: QuestionsData) => mercedesOptionsFilter(data.clothes1.value || '') || ribFilter(data.clothes1.value || '');
  } else if (opelOptionsFilter(option.value)) {
    callback = (data: QuestionsData) => opelOptionsFilter(data.clothes1.value || '') || ribFilter(data.clothes1.value || '');
  } else if (psaOptionsFilter(option.value)) {
    callback = (data: QuestionsData) => psaOptionsFilter(data.clothes1.value || '') || ribFilter(data.clothes1.value || '');
  } else if (renaultOptionsFilter(option.value)) {
    callback = (data: QuestionsData) => renaultOptionsFilter(data.clothes1.value || '') || ribFilter(data.clothes1.value || '');
  } else if (volkswagenOptionsFilter(option.value)) {
    callback = (data: QuestionsData) => volkswagenOptionsFilter(data.clothes1.value || '') || ribFilter(data.clothes1.value || '');
  } else {
    callback = (_: QuestionsData) => false;
  }
  return {...option, enabled: callback}
})

let baseVersionOfC66GEnabledCallback = (data: QuestionsData) => (data?.seatWidth?.value || '').indexOf('3P') === -1 && [i18n.t('translations:steps:handlePad:integratedAxleLeft')].includes(data?.handlePad?.value || '');
let baseVersionOfC66DEnabledCallback = (data: QuestionsData) => (data?.seatWidth?.value || '').indexOf('3P') === -1 && [i18n.t('translations:steps:handlePad:integratedAxleRight')].includes(data?.handlePad?.value || '');
let piedFixeC64HelpPdfs = (_: QuestionsData) => ['/assets/pdfs/help/version_pied_fixe_aca_6466.pdf'];
export let baseVersionOptions: QuestionOption[] = [
  {
    label: i18n.t('translations:steps:fixedFrameVersion:values:fixed'),
    value: 'Pied FIX',
    helpPdfs: piedFixeC64HelpPdfs
  },
  {
    label: i18n.t('translations:steps:fixedFrameVersion:values:c64'),
    value: 'Pied C64',
    helpPdfs: piedFixeC64HelpPdfs,
    enabled: (data) => !(data.interface.value === i18n.t('yes') && data.seatWidth?.value?.indexOf('3P') > -1)
  },
  {
    label: i18n.t('translations:steps:fixedFrameVersion:values:c66d'),
    value: 'Pied C66D',
    enabled: baseVersionOfC66DEnabledCallback
  },
  {
    label: i18n.t('translations:steps:fixedFrameVersion:values:c66g'),
    value: 'Pied C66G',
    enabled: baseVersionOfC66GEnabledCallback
  },
]

export let bracketOnPlateOptions: QuestionOption[] = [
  {label: i18n.t('without'), value: 'Ø'},
  {label: 'EPD95', value: 'EPD95'},
  {label: 'EPG95', value: 'EPG95'},
]

let baseTypeNoCallback = (data: QuestionsData) => data?.interface?.value !== i18n.t('yes');
let baseTypeHelpPdfs = (_: QuestionsData) => ['/assets/pdfs/help/percage_type_pied.pdf'];
export let baseTypeOptions: QuestionOption[] = [
  {label: 'A', value: 'A', enabled: baseTypeNoCallback, helpPdfs: baseTypeHelpPdfs},
  {label: 'B', value: 'B', helpPdfs: baseTypeHelpPdfs},
  {label: 'C', value: 'C', enabled: baseTypeNoCallback, helpPdfs: baseTypeHelpPdfs},
  {label: 'D', value: 'D', helpPdfs: baseTypeHelpPdfs},
  {label: 'X (+ 30€)', value: 'X (+ 30€)', enabled: baseTypeNoCallback, helpPdfs: baseTypeHelpPdfs},
]

let offsetHeightHelpPdfs = (data: QuestionsData) => {
  switch (data?.baseVersion?.value || '') {
    case 'Pied FIX':
      return ['/assets/pdfs/help/hauteur_dep_fixe.pdf'];
    case 'Pied C64':
      return ['/assets/pdfs/help/hauteur_dep_aca_64_r.pdf'];
    case 'Pied C66D':
    case 'Pied C66G':
      return ['/assets/pdfs/help/hauteur_dep_aca_66_r.pdf'];
    default:
      return [];
  }
};
export let offsetHeightOptions: QuestionOption[] = [
  170, 180, 190, 200, 210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 340, 350,
  360, 370, 380, 390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520, 530, 540, 550, 560, 570
].map(value => ({
  label: value.toString(), value: value.toString(), helpPdfs: offsetHeightHelpPdfs, enabled: (data: QuestionsData) => {
    if (data?.seatWidth?.value === '060 - 1P') {
      if (data?.baseVersion?.value === 'Pied FIX') {
        return value >= 170 && value <= 570;
      } else if (data?.baseVersion?.value === 'Pied C64') {
        return value >= 220 && value <= 570;
      } else if (data?.baseVersion?.value === 'Pied C66D'
        || data?.baseVersion?.value === 'Pied C66G') {
        return value >= 220 && value <= 540;
      } else {
        return false;
      }
    } else {
      if (data?.baseVersion?.value === 'Pied FIX') {
        return value >= 170 && value <= 415;
      } else if (data?.baseVersion?.value === 'Pied C64') {
        return value >= 220 && value <= 415;
      } else if (data?.baseVersion?.value === 'Pied C66D'
        || data?.baseVersion?.value === 'Pied C66G') {
        return value >= 220 && value <= 385;
      } else {
        return false;
      }
    }
  }
}))

let offsetHelpPdfs = (data: QuestionsData) => {
  switch (data?.baseVersion?.value || '') {
    case 'Pied FIX':
      return ['/assets/pdfs/help/deport_fixe.pdf'];
    case 'Pied C64':
      return ['/assets/pdfs/help/deport_aca_64_r.pdf'];
    case 'Pied C66D':
    case 'Pied C66G':
      return ['/assets/pdfs/help/deport_aca_66_r.pdf'];
    default:
      return [];
  }
};
let offsetOption000Enabled = (data: QuestionsData) => !['Pied C64', 'Pied C66G', 'Pied C66D'].includes(data?.baseVersion?.value || '');
export let offsetOptions: QuestionOption[] = [
  {label: '000', value: '000', helpPdfs: offsetHelpPdfs, enabled: offsetOption000Enabled},
  {label: '040', value: '040', helpPdfs: offsetHelpPdfs},
  {label: '100', value: '100', helpPdfs: offsetHelpPdfs},
  {label: '110', value: '110', helpPdfs: offsetHelpPdfs},
  {label: '120', value: '120', helpPdfs: offsetHelpPdfs},
  {label: '130', value: '130', helpPdfs: offsetHelpPdfs},
  {label: '140', value: '140', helpPdfs: offsetHelpPdfs},
  {label: '150', value: '150', helpPdfs: offsetHelpPdfs},
  {label: '160', value: '160', helpPdfs: offsetHelpPdfs},
  {label: '170', value: '170', helpPdfs: offsetHelpPdfs},
  {label: '180', value: '180', helpPdfs: offsetHelpPdfs},
  {label: '190', value: '190', helpPdfs: offsetHelpPdfs},
  {label: '200', value: '200', helpPdfs: offsetHelpPdfs},
  {label: '210', value: '210', helpPdfs: offsetHelpPdfs},
  {label: '220', value: '220', helpPdfs: offsetHelpPdfs},
  {label: '230', value: '230', helpPdfs: offsetHelpPdfs},
  {label: '240', value: '240', helpPdfs: offsetHelpPdfs},
  {label: '250', value: '250', helpPdfs: offsetHelpPdfs},
  {label: '260', value: '260', helpPdfs: offsetHelpPdfs},
  {label: '270', value: '270', helpPdfs: offsetHelpPdfs},
  {label: '280', value: '280', helpPdfs: offsetHelpPdfs},
  {label: '290', value: '290', helpPdfs: offsetHelpPdfs},
  {label: '300', value: '300', helpPdfs: offsetHelpPdfs},
]
