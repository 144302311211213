// Pour plus d'informations sur le fonctionnement, voir le Readme de la version Godot du projet.

export enum EMaterial {
    MATERIAL_GLOBAL = "material_1",
    MATERIAL_BACK = "material_2",
    MATERIAL_MATELASSE = "material_3"
}

export interface MaterialData {
    material_1: string,
    material_2: string,
    material_3: string,
}

export interface GameEngineSelectionData {
    sdecs: string,
    pieds: string,
    assises: string,
    dossiers: string,
    accoudoirs: string,
    widths: string,
    ceintures: string,
}

export interface GameEngineData {
    name: string,
    materials: MaterialData,
    data: GameEngineSelectionData
}

export function initEmpty(): GameEngineData {
    let data = {
        name: "1p",
        materials: {
            material_1: "none",
            material_2: "none",
            material_3: "none"
        },
        data: {
            sdecs: "2dos",
            pieds: "neptune",
            assises: "assise",
            dossiers: "appuie_tete",
            accoudoirs: "sans_dgauche",
            widths: "60",
            ceintures: "without",
        }
    }
    return data as GameEngineData
}
