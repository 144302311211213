import React from "react";
import './button-image.scss';

interface ButtonImageProps {
    onClick: (e : any) => void,
    selected: boolean,
    img: string
}

export default class ButtonImage extends React.Component <ButtonImageProps, {}> {

    constructor (props : ButtonImageProps) {
        super (props)
    }

    render () {
        return (
            <button type="button" onClick={e => this.props.onClick(e)} className={"button-image " + (this.props.selected ? 'selected' : '')}>
                <img src={this.props.img} alt={this.props.img}/>
                <div>
                    {this.props.children}
                </div>
            </button>
        )
    }

}
