import React from "react";
import './btn-toggle.scss';

interface BtnToggleProps {
  toggle: (e : any) => void,
  active: boolean,
  img: string,
}

export default class BtnToggle extends React.Component <BtnToggleProps, {}> {
  constructor (props : BtnToggleProps) {
    super (props)
  }

  render () {
    return (
      <button type="button" onClick={this.props.toggle} className="btn-toggle">
        <img src={`${this.props.img}${this.props.active ? '_on' : '_off'}.svg`} alt={this.props.img}/>
      </button>
    )
  }
}