export default {
  previous: "Précédent",
  next: "Suivant",
  validate: "Valider",
  download: "Télécharger",
  completingSetup: "Finalisation de la configuration",
  enterConfigurationCode: "Entrer le code de configuration",
  left: "Gauche",
  right: "Droite",
  yes: "Oui",
  no: "Non",
  with: "Avec",
  without: "Sans",
  restart: "Relancer",
  other: "Autre",
  steps: {
    seatWidth: {
      title: "Largeur banquette",
      seatNumber: {
        title: "Nombre de places",
        label: "{{count}} place",
        label_plural: "{{count}} places"
      },
      seatWidth: {
        title: "Largeur banquette",
        labelLeft: "{{count}} mm gauche",
        labelRight: "{{count}} mm droite",
        label: "{{count}} mm"
      }
    },
    sleepLength: {
      title: "Longueur de couchage",
      labelAltair: "ALTAIR (couchage : {{count}} mm)",
      labelNeptune: "NEPTUNE (couchage : {{count}} mm)"
    },
    armRest: {
      title: "accoudoir",
      without: "sans",
      left: "gauche",
      right: "droite",
      leftRight: "gauche et droite",
      center: "milieu",
      centerLeftRight: "milieu, gauche et droite",
      all: "2 milieux, gauche et droite"
    },
    clothDist: {
      title: "Répartition tissu",
      without: "sans housse",
      one: "monoton",
      two: "bi-ton"
    },
    clothes: {
      title: "Tissu",
      label: "Tissu {{count}}",
      labelRev1: "Tissu 1 (Bande matelassée)",
      labelRev2: "Tissu 2 (Reste du siège)",
      noElementMessage: "Vous avez choisi sans housse donc vous pouvez passer directement à la suite",
      constructorsLabel: "Gamme",
      texturesLabel: "Textures",
      brands: {
        rib: "RIB",
        ford: "FORD",
        mercedes: "MERCEDES",
        opel: "OPEL",
        psa: "PSA",
        renault: "RENAULT",
        volkswagen: "VOLKSWAGEN"
      },
      textures: {
        draduro7001: "Draduro 7001",
        draduro8009: "Draduro 8009",
        draduro8032: "Draduro 8032",
        draduro8212: "Draduro 8212",
        draduro8335: "Draduro 8335",
        draduro8901: "Draduro 8901",
        draduro8915: "Draduro 8915",
        enduro470: "Enduro 470",
        enduro1478: "Enduro 1478",
        enduro1677: "Enduro 1677",
        enduro2687: "Enduro 2687",
        enduro7005: "Enduro 7005",
        enduro8015: "Enduro 8015",
        enduro8440: "Enduro 8440",
        enduro8504: "Enduro 8504",
        enduro8514: "Enduro 8514",
        enduro8800: "Enduro 8800",
        enduro8841: "Enduro 8841",
        enduro8900: "Enduro 8900",
        enduro8923: "Enduro 8923",
        resistRosa: "Resist Rosa",
        resistAzaleaBlack: "Resist Azalea Noir",
        black: "DRADURON",
        fTransitcustom: "FORD TRANSIT CUSTOM",
        forduni: "FORD MAX",
        mercedeslima: "MERCEDES LIMA",
        mercedestunja: "TUNJA MOTIF",
        mercedestunjauni: "Tunja uni",
        limamotif: "LIMA MOTIF",
        limauni: "LIMA UNI",
        oVivaroconnectm: "VIVARO CONNECT M",
        oVivaroconnectu: "VIVARO CONNECT U",
        peugeotboxer: "PEUGEOT BOXER",
        psakobrasilia: "PSA KO BRASILIA",
        psakocuritiba: "PSA KO CURITIBA",
        fiatscudo: "FIAT SCUDO",
        scudobleuuni: "SCUDO BLEU UNI",
        fDucato2014m: "FIAT Crêpe 157 Gris",
        fDucato2014u: "FIAT Crêpe 157 Noir",
        renaultdino: "RENAULT DINO",
        renaultkario: "RENAULT KARIO",
        safariBlack: "SAFARI NOIR",
        renaultx82java: "RENAULT JAVA",
        t5boxuni: "T5 BOX UNI",
        tasamouni: "TASAMO UNI",
        vwaustin: "VW AUSTIN",
        vwbricks: "VW BRICKS",
        vwdoublegrid: "VW DOUBLE GRID",
        vwt4inca: "VW T4 INCA",
        vwt5incaii: "VW T5 INCA II",
        vwt5place: "VW T5 PLACE",
        vwt6simora: "VW T6 SIMORA",
        titane: "TITANE",
        kompo: "RENAULT KOMPO"
      }
    },
    handlePad: {
      title: "Poignée et PDAD",
      handleAxleLeft: "Poignée dans l'axe à gauche",
      handleAxleRight: "Poignée dans l'axe à droite",
      integratedAxleLeft: "Poignée haute à gauche",
      integratedAxleRight: "Poignée haute à droite",
      handleAxlePdadLeft: "Poignée dans l'axe à gauche + PDAD{{count}}",
      handleAxlePdadRight: "Poignée dans l'axe à droite + PDAD{{count}}",
    },
    constructor: {
      title: "Constructeur",
      titleType: "Modèle",
      brands: {
        citroen: "CITROËN",
        fiat: "FIAT",
        ford: "FORD",
        man: "MAN",
        mercedes: "MERCEDES",
        nissan: "NISSAN",
        opel: "OPEL - VAUXHALL",
        peugeot: "PEUGEOT",
        renault: "RENAULT",
        toyota: "TOYOTA",
        volkswagen: "VOLKSWAGEN"
      }
    },
    frameType: {
      title: "Type de pied"
    },
    drivingPosition: {
      title: "Position de conduite"
    },
    fixationFrame: {
      title: "Interface"
    },
    benchseatPosition: {
      title: "Position de la banquette",
      maxiFront: "Maxi avant",
      maxiRear: "Maxi arrière",
      other: "Autre",
    },
    sleepingHeight: {
      title: "Hauteur de couchage"
    },
    sdecs: {
      title: "SDEC + SDECS (section de découpe)",
      values: {
        without: "Sans",
        "80_X_100": "80X100",
        "90_X_100": "90X100",
        "90_X_140": "90X140",
        "100_X_200": "100X200",
        "140_X_140": "140X140",
        "140_X_200": "140X200"
      }
    },
    thermoflex: {
      title: "Surmatelas 3D"
    },
    isofix: {
      title: "ISOFIX Toptether"
    },
    squareArticulation: {
      title: "Équerre sur les platines"
    },
    wheelArchOffsetHeight: {
      title: "Hauteur sous déport"
    },
    wheelArchOffsetLeft: {
      title: "Déport gauche"
    },
    wheelArchOffsetRight: {
      title: "Déport droit"
    },
    fixedFrameVersion: {
      title: "Version pied",
      values: {
        without: "Sans pied",
        fixed: "Pied FIX",
        c64: "C64",
        c66d: "C66D",
        c66g: "C66G"
      }
    }
  }
}
