
// #00448c
import React from 'react';
import './help-button.scss';

interface HelpButtonProps {
  onClick: () => void;
  size: number;
  marginLeft?: number;
}

const HelpButton = ({ onClick, size, marginLeft } : HelpButtonProps) => {
  return (
    <a onClick={() => onClick()} className="help-button">
      <img src={'/assets/img/toggles/help_web_button.png'} style={{width: size, height: size, marginLeft: marginLeft}} alt={"help"}/>
    </a>
  );
}

export default HelpButton;
