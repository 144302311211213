import { ParsedQuery } from "query-string";
import React, { useState } from "react";
import CodeForm from "../components/elements/questions/code_form";
import Question from "../components/elements/questions/question";
import { parseData, QuestionsData } from "../components/elements/questions/common";
import StepContainer from "../components/elements/step-container/step-container";
import jspdf from "jspdf";
import i18n from "../utils/i18n";

interface QuestionsProps {
  query: ParsedQuery<string>
}

const Questions = ({ query }: QuestionsProps) => {

  let [data, setData] = useState<QuestionsData>(parseData(query))

  const onValid = async () => {
    const pdf = new jspdf()
    let fontName = pdf.getFont().fontName
    pdf.setFont(fontName, "bold")
    pdf.text("Récapitulatif de la configuration", 100, 10, {
      align: 'center'
    })
    let offset = 1
    let datas = [
      { label: i18n.t('translations:steps:constructor:title'), value: data.constructor.value },
      { label: i18n.t('translations:steps:constructor:titleType'), value: data.type.value },
      { label: i18n.t('translations:steps:drivingPosition:title'), value: data.drive.value },
      { label: i18n.t('translations:steps:fixationFrame:title'), value: data.interface.value },
      { label: 'Longeur du véhicule', value: data.vehiculeLength.value },
      { label: i18n.t('translations:steps:benchseatPosition:title'), value: data.implementation.value },
      // { label: i18n.t('translations:steps:sleepingHeight:title'), value: data.bedHeight.value },
      { label: i18n.t('translations:steps:handlePad:title'), value: data.handlePad.value },
      { label: i18n.t('translations:steps:seatWidth:title'), value: data.seatWidth.value },
      { label: i18n.t('translations:steps:sleepLength:title'), value: data.sleepLength.value },
      { label: i18n.t('translations:steps:sdecs:title'), value: data.sdec.value },
      { label: i18n.t('translations:steps:armRest:title'), value: data.armrest.value },
      { label: i18n.t('translations:steps:thermoflex:title'), value: data.thermo.value },
      { label: i18n.t('translations:steps:isofix:title'), value: data.isofix.value },
      { label: i18n.t('translations:steps:clothDist:title'), value: data.clothesDistribution.value },
      { label: i18n.t('translations:steps:clothes:labelRev1'), value: data.clothes1.value },
      { label: i18n.t('translations:steps:clothes:labelRev2'), value: data.clothes2.value },
      { label: i18n.t('translations:steps:fixedFrameVersion:title'), value: data.baseVersion.value },
      // { label: i18n.t('translations:steps:squareArticulation:title'), value: data.bracketOnPlate.value },
      { label: i18n.t('translations:steps:frameType:title'), value: data.baseType.value },
      { label: i18n.t('translations:steps:wheelArchOffsetHeight:title'), value: data.offsetHeight.value },
      { label: i18n.t('translations:steps:wheelArchOffsetLeft:title'), value: data.leftOffset.value },
      { label: i18n.t('translations:steps:wheelArchOffsetRight:title'), value: data.rightOffset.value },
    ]
    datas.forEach((el, i) => {
      pdf.setFont(fontName, "bold")
      pdf.text(`${el.label}:`, 10, 10 * (i + 1 + offset))
      pdf.setFont(fontName, "normal")
      let width = pdf.getTextDimensions(el.label).w
      pdf.text(`${el.value}`, 10 + width + 10, 10 * (i + 1 + offset))
    })
    pdf.save(`${(new Date()).toISOString()}.pdf`)
  }

  return (
    <div id="main_questions">
      <div id="breadcrumb-bar">
        <CodeForm query={query} />
        <img className={'scopema-logo'} src="./assets/img/Scopema-logo.png" alt="logo-scopema" />
      </div>

      <StepContainer
        title={i18n.t('completingSetup')}
        onNextClick={() => null}
        onPrevClick={() => null}
        onValidClick={onValid}
        showNext={false}
        showPrev={false}
        showValid={true}
        showToggles={false}
        onResetClick={() => {}}
      >
        <div id="questions">
          <Question data={data} title={i18n.t('translations:steps:constructor:title')} setValue={value => setData({ ...data, constructor: { ...data.constructor, value } })} questionData={data.constructor} />
          <Question data={data} title={i18n.t('translations:steps:constructor:titleType')} setValue={value => setData({ ...data, type: { ...data.type, value } })} questionData={data.type} />
          <Question data={data} title={i18n.t('translations:steps:drivingPosition:title')} setValue={value => setData({ ...data, drive: { ...data.drive, value } })} questionData={data.drive} />
          <Question data={data} title={i18n.t('translations:steps:fixationFrame:title')} setValue={value => setData({ ...data, interface: { ...data.interface, value } })} questionData={data.interface} />
          <Question data={data} title={'Longeur du véhicule'} setValue={value => setData({ ...data, vehiculeLength: { ...data.vehiculeLength, value } })} questionData={data.vehiculeLength} />
          <Question data={data} title={i18n.t('translations:steps:benchseatPosition:title')} setValue={value => setData({ ...data, implementation: { ...data.implementation, value } })} questionData={data.implementation} />
          {/* <Question data={data} title={i18n.t('translations:steps:sleepingHeight:title')} setValue={value => setData({ ...data, bedHeight: { ...data.bedHeight, value } })} value={data.bedHeight.value} options={data.bedHeight.options} /> */}
          <Question data={data} title={i18n.t('translations:steps:handlePad:title')} setValue={value => setData({ ...data, handlePad: { ...data.handlePad, value } })} questionData={data.handlePad} />
          <Question data={data} title={i18n.t('translations:steps:seatWidth:title')} setValue={value => setData({ ...data, seatWidth: { ...data.seatWidth, value } })} questionData={data.seatWidth} />
          <Question data={data} title={i18n.t('translations:steps:sleepLength:title')} setValue={value => setData({ ...data, sleepLength: { ...data.sleepLength, value } })} questionData={data.sleepLength} />
          <Question data={data} title={i18n.t('translations:steps:sdecs:title')} setValue={value => setData({ ...data, sdec: { ...data.sdec, value } })} questionData={data.sdec} />
          <Question data={data} title={i18n.t('translations:steps:armRest:title')} setValue={value => setData({ ...data, armrest: { ...data.armrest, value } })} questionData={data.armrest} />
          <Question data={data} title={i18n.t('translations:steps:thermoflex:title')} setValue={value => setData({ ...data, thermo: { ...data.thermo, value } })} questionData={data.thermo} />
          <Question data={data} title={i18n.t('translations:steps:isofix:title')} setValue={value => setData({ ...data, isofix: { ...data.isofix, value } })} questionData={data.isofix} />
          <Question data={data} title={i18n.t('translations:steps:clothDist:title')} setValue={value => setData({ ...data, clothesDistribution: { ...data.clothesDistribution, value }, clothes1: { ...data.clothes1, value: '' }, clothes2: { ...data.clothes2, value: '' } })} questionData={data.clothesDistribution} />
          {[i18n.t('translations:steps:clothDist:two'), i18n.t('translations:steps:clothDist:one')].includes(data?.clothesDistribution?.value || '') && <Question data={data} title={i18n.t('translations:steps:clothes:labelRev1')} setValue={value => setData({ ...data, clothes1: { ...data.clothes1, value } })} questionData={data.clothes1} />}
          {[i18n.t('translations:steps:clothDist:two')].includes(data?.clothesDistribution?.value || '') && <Question data={data} title={i18n.t('translations:steps:clothes:labelRev2')} setValue={value => setData({ ...data, clothes2: { ...data.clothes2, value } })} questionData={data.clothes2} />}
          <Question data={data} title={i18n.t('translations:steps:fixedFrameVersion:title')} setValue={value => setData({ ...data, baseVersion: { ...data.baseVersion, value } })} questionData={data.baseVersion} />
          {/* {![
            'Pied C64', 'Ø', 'Pied FIX', ''
          ].includes(data?.baseVersion?.value || '') && <Question data={data} title={i18n.t('translations:steps:squareArticulation:title')} setValue={value => setData({ ...data, bracketOnPlate: { ...data.bracketOnPlate, value } })} questionData={{...data.bracketOnPlate, options:data.bracketOnPlate.options.filter(e => ((data?.baseVersion?.value === 'Pied C66D') && (e.label === 'EPD95' || e.label === i18n.t('without'))) || (data?.baseVersion?.value === 'Pied C66G' && (e.label === 'EPG95' || e.label === i18n.t('without'))))}} />} */}
          <Question data={data} title={i18n.t('translations:steps:frameType:title')} setValue={value => setData({ ...data, baseType: { ...data.baseType, value } })} questionData={data.baseType} />
          <Question data={data} title={i18n.t('translations:steps:wheelArchOffsetHeight:title')} setValue={value => setData({ ...data, offsetHeight: { ...data.offsetHeight, value } })} questionData={data.offsetHeight} />
          <Question data={data} title={i18n.t('translations:steps:wheelArchOffsetLeft:title')} setValue={value => setData({ ...data, leftOffset: { ...data.leftOffset, value } })} questionData={data.leftOffset} />
          <Question data={data} title={i18n.t('translations:steps:wheelArchOffsetRight:title')} setValue={value => setData({ ...data, rightOffset: { ...data.rightOffset, value } })} questionData={data.rightOffset} />
        </div>
      </StepContainer>
    </div>
  )
}

export default Questions;