import React from "react";
import BtnGroup from "../../items/btn-group/btn-group";
import { QuestionProps } from "./common";

export default ({ setValue, title, data, questionData } : QuestionProps) => {

    let opts = questionData.options.filter(option => option.enabled ? option.enabled(data) : true);

    if (opts && opts.length > 0) {
        let btnHelpPdfs : string[] = opts.filter(option => option.helpPdfs && option.helpPdfs.length != 0)
            .reduce((array, option) => array.concat(option.helpPdfs!(data) || []), [] as string[])
            .filter((val, ind, arr) => arr.indexOf(val) === ind); // UNIQ VALUES
        if (opts.length === 1 && questionData.value !== opts[0].value) setValue(opts[0].value)
        return (
            <div className="question">
                <BtnGroup title={title} helpPdfs={btnHelpPdfs} titleStyle={{textAlign: 'center'}} withOtherButton={questionData.otherOption} options={opts.map(option => {return {label: option.label, value: option.value, img: option.img}})} selectedOptionValue={questionData.value} onSelect={setValue}/>
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}
