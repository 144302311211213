import { ParsedQuery } from "query-string";
import { parseCode } from "../../../utils/code_parser";
import { LocalStorageData } from "../../../utils/localstorage_data";
import {
    constructorOptions,
    typeOptions,
    armrestOptions,
    bedHeightOptions,
    driveOptions,
    implementationOptions,
    interfaceOptions,
    vehiculeOptions,
    seatWidthOptions,
    handlePadOptions,
    sleepLengthOptions,
    sdecOptions,
    thermoflexOptions,
    isofixOptions,
    bracketOnPlateOptions,
    baseTypeOptions,
    offsetHeightOptions,
    offsetOptions,
    baseVersionOptions,
    clothesDistributionOptions,
    clothesOptions,
    clothes2Options,
} from "./variables";

export interface QuestionProps {
    setValue: (data: any) => void,
    title: string,
    data: QuestionsData,
    questionData: QuestionData,
}

export interface QuestionData {
    value: string,
    options: QuestionOption[],
    otherOption: boolean,
}

export interface QuestionsData {
    constructor: QuestionData,
    type: QuestionData,
    drive: QuestionData,
    interface: QuestionData,
    vehiculeLength: QuestionData,
    implementation: QuestionData,
    bedHeight: QuestionData,
    seatWidth: QuestionData,
    handlePad: QuestionData,
    sleepLength: QuestionData,
    sdec: QuestionData,
    armrest: QuestionData,
    thermo: QuestionData,
    isofix: QuestionData,
    baseVersion: QuestionData,
    bracketOnPlate: QuestionData,
    baseType: QuestionData,
    offsetHeight: QuestionData,
    leftOffset: QuestionData,
    rightOffset: QuestionData,
    clothesDistribution: QuestionData,
    clothes1: QuestionData,
    clothes2: QuestionData,
}

export interface QuestionOption {
    label: string,
    value: string,
    godotValues?: string[],
    img?:string,
    helpPdfs?: (data: QuestionsData) => string[],
    enabled?:(data: QuestionsData) => boolean,
}

export function getOptionValue(options: QuestionOption[], value: string | null | undefined) : string {
    return options.find(option => option.godotValues?.includes(value || ''))?.value || ''
}

export function parseData (query : ParsedQuery<string>) : QuestionsData {
    // TEST ####A-R##4#B#C#####C#draduro7001,draduro8009
    let localStorageData : LocalStorageData | null = parseCode(query['code'] as string);

    let data : QuestionsData = {
        constructor: {
            value: '',
            options: constructorOptions,
            otherOption: false,
        },
        type: {
            value: '',
            options: typeOptions,
            otherOption: false,
        },
        drive: {
            value: '',
            options: driveOptions,
            otherOption: false,
        },
        interface: {
            value: '',
            options: interfaceOptions,
            otherOption: false,
        },
        vehiculeLength: {
            value: '',
            options: vehiculeOptions,
            otherOption: false,
        },
        implementation: {
            value: '',
            options: implementationOptions,
            otherOption: false,
        },
        bedHeight: {
            value: '',
            options: bedHeightOptions,
            otherOption: false,
        },
        seatWidth: {
            value: getOptionValue(seatWidthOptions, localStorageData?.seatWidth.data?.width),
            options: seatWidthOptions,
            otherOption: false,
        },
        handlePad: {
            value: '',
            options: handlePadOptions,
            otherOption: false,
        },
        sleepLength: {
            value: getOptionValue(sleepLengthOptions, localStorageData?.sleepLength.data?.length),
            options: sleepLengthOptions,
            otherOption: false,
        },
        sdec: {
            value: '',
            options: sdecOptions,
            otherOption: true,
        },
        armrest: {
            value: getOptionValue(armrestOptions, localStorageData?.armrest.data?.armrest),
            options: armrestOptions,
            otherOption: false,
        },
        thermo: {
            value: '',
            options: thermoflexOptions,
            otherOption: false,
        },
        isofix: {
            value: '',
            options: isofixOptions,
            otherOption: false,
        },
        clothesDistribution: {
            value: getOptionValue(clothesDistributionOptions, localStorageData?.clothDist.data?.clothDistribution),
            options: clothesDistributionOptions,
            otherOption: false,
        },
        clothes1: {
            value: getOptionValue(clothesOptions, localStorageData?.clothes.data?.cloth_1),
            options: clothesOptions,
            otherOption: false,
        },
        clothes2: {
            value: getOptionValue(clothesOptions, localStorageData?.clothes.data?.cloth_2),
            options: clothes2Options,
            otherOption: false,
        },
        baseVersion: {
            value: '',
            options: baseVersionOptions,
            otherOption: false,
        },
        bracketOnPlate: {
            value: '',
            options: bracketOnPlateOptions,
            otherOption: false,
        },
        baseType: {
            value: '',
            options: baseTypeOptions,
            otherOption: false,
        },
        offsetHeight: {
            value: '',
            options: offsetHeightOptions,
            otherOption: false,
        },
        leftOffset: {
            value: '',
            options: offsetOptions,
            otherOption: false,
        },
        rightOffset: {
            value: '',
            options: offsetOptions,
            otherOption: false,
        },
    }
    return data
}
