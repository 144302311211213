export enum Route {
  HOME,
  REVENDEUR
}

export function getRoute() : Route {
  let hash = location.hash.replace('#', '')
  if (hash.startsWith('/revendeur')) return Route.REVENDEUR
  else return Route.HOME
}

export function getLang() : string | null {
  let hash = location.hash.replace('#', '')
  let hashSplitted = hash.split('/')
  if (hashSplitted.length == 0) return null;
  return hashSplitted[hashSplitted.length - 1]
}