import React, { useEffect, useState } from "react";
import i18n from "../../../utils/i18n";
import BtnGroup from "../btn-group/btn-group";
import "./color-selector.scss";

export interface TextureSelectorOption {
    label:string,
    value:any,
    enabled?:() => boolean,
    img?:string
}

export interface ColorSelectorOption {
    brand: string,
    textures: TextureSelectorOption[]
}

interface ColorSelectorProps {
    title: string,
    options: ColorSelectorOption[],
    state: string | null,
    onChange: (option: string | null, brand: string) => void
}

export default function ColorSelector({
    title,
    options,
    state,
    onChange
} : ColorSelectorProps) {
    useEffect(() => {
        setBrands(options.map((option) => option.brand))
    }, [options])
    let [brands, setBrands] = useState<string[]>(options.map((option) => option.brand));
    let [selectedOption, setSelectedOption] = useState<ColorSelectorOption | null>(null);

    const onSelectBrand = (v: string|null) => {
        setSelectedOption(options.find(option => option.brand === v) || null);
        if (v) onChange(null, v);
    }

    return (
        <div className="stepForm color-selector">
            <h4 className="form-title">{title}</h4>
            { brands.length > 0 && <BtnGroup title={i18n.t('translations:steps:clothes:constructorsLabel')} options={options.map(option => { return {label: option.brand, value: option.brand}})} selectedOptionValue={selectedOption?.brand || null} onSelect={onSelectBrand}/> }
            { selectedOption && <BtnGroup title={i18n.t('translations:steps:clothes:texturesLabel')} options={selectedOption?.textures} selectedOptionValue={state} onSelect={(value) => onChange(value, selectedOption!.brand)}/> }
        </div>
    )

}