export default {
  previous: "Previous",
  next: "Next",
  validate: "Validate",
  download: "Download",
  completingSetup: "Completing the setup",
  enterConfigurationCode: "Enter the configuration code",
  left: "Left",
  right: "Right",
  yes: "Yes",
  no: "No",
  with: "WITH",
  without: "WITHOUT",
  restart: "Restart",
  other: "Other",
  steps: {
    seatWidth: {
      title: "Benchseat width",
      seatNumber: {
        title: "Number of seater",
        label: "{{count}} seater",
        label_plural: "{{count}} seaters"
      },
      seatWidth: {
        title: "Benchseat width",
        labelLeft: "{{count}} mm left",
        labelRight: "{{count}} mm right",
        label: "{{count}} mm"
      }
    },
    sleepLength: {
      title: "Sleeping length",
      labelAltair: "ALTAIR (Sleeping length : {{count}} mm)",
      labelNeptune: "NEPTUNE (Sleeping length : {{count}} mm)"
    },
    armRest: {
      title: "arm-rest",
      without: "without",
      left: "Left",
      right: "Right",
      leftRight: "left and right",
      center: "center",
      centerLeftRight: "center, left and right",
      all: "2 in the center, left and right"
    },
    clothDist: {
      title: "Distribution of fabrics",
      without: "without fabric",
      one: "a single fabric ",
      two: "two-tone"
    },
    clothes: {
      title: "Fabric",
      label: "Fabric {{count}}",
      labelRev1: "Tissu 1 (Padded band)",
      labelRev2: "Tissu 2 (Remainder of the seat)",
      noElementMessage: "You have chosen without fabric, go directly to next",
      constructorsLabel: "Range",
      texturesLabel: "Textures",
      brands: {
        rib: "RIB",
        ford: "FORD",
        mercedes: "MERCEDES",
        opel: "OPEL",
        psa: "PSA",
        renault: "RENAULT",
        volkswagen: "VOLKSWAGEN"
      },
      textures: {
        draduro7001: "Draduro 7001",
        draduro8009: "Draduro 8009",
        draduro8032: "Draduro 8032",
        draduro8212: "Draduro 8212",
        draduro8335: "Draduro 8335",
        draduro8901: "Draduro 8901",
        draduro8915: "Draduro 8915",
        enduro470: "Enduro 470",
        enduro1478: "Enduro 1478",
        enduro1677: "Enduro 1677",
        enduro2687: "Enduro 2687",
        enduro7005: "Enduro 7005",
        enduro8015: "Enduro 8015",
        enduro8440: "Enduro 8440",
        enduro8504: "Enduro 8504",
        enduro8514: "Enduro 8514",
        enduro8800: "Enduro 8800",
        enduro8841: "Enduro 8841",
        enduro8900: "Enduro 8900",
        enduro8923: "Enduro 8923",
        resistRosa: "Resist Rosa",
        resistAzaleaBlack: "Resist Azalea Black",
        black: "DRADURON",
        fTransitcustom: "FORD TRANSIT CUSTOM",
        forduni: "FORD MAX",
        mercedeslima: "MERCEDES LIMA",
        mercedestunja: "TUNJA MOTIF",
        mercedestunjauni: "Tunja Plain",
        limamotif: "LIMA Patterned",
        limauni: "LIMA Plain",
        oVivaroconnectm: "VIVARO CONNECT M",
        oVivaroconnectu: "VIVARO CONNECT U",
        peugeotboxer: "PEUGEOT BOXER",
        psakobrasilia: "PSA KO BRASILIA",
        psakocuritiba: "PSA KO CURITIBA",
        fiatscudo: "FIAT SCUDO",
        scudobleuuni: "SCUDO BLUE PLAIN",
        fDucato2014m: "FIAT Crêpe 157 Gris",
        fDucato2014u: "FIAT Crêpe 157 Noir",
        renaultdino: "RENAULT DINO",
        renaultkario: "RENAULT KARIO",
        safariBlack: "SAFARI NOIR",
        renaultx82java: "RENAULT JAVA",
        t5boxuni: "T5 BOX Plain",
        tasamouni: "TASAMO Plain",
        vwaustin: "VW AUSTIN",
        vwbricks: "VW BRICKS",
        vwdoublegrid: "VW DOUBLE GRID",
        vwt4inca: "VW T4 INCA",
        vwt5incaii: "VW T5 INCA II",
        vwt5place: "VW T5 PLACE",
        vwt6simora: "VW T6 SIMORA",
        titane: "TITANE",
        kompo: "RENAULT KOMPO"
      }
    },
    handlePad: {
      title: "Handle and PDAD",
      handleAxleLeft: "Handle on axle on left",
      handleAxleRight: "Handle on axle on right",
      integratedAxleLeft: "Integrated handle on left side",
      integratedAxleRight: "Integrated handle on right side",
      handleAxlePdadLeft: "Handle on axle on left + {{count}}°",
      handleAxlePdadRight: "Handle on axle on right + {{count}}°",
    },
    constructor: {
      title: "OEM",
      titleType: "Type",
      brands: {
        citroen: "CITROËN",
        fiat: "FIAT",
        ford: "FORD",
        man: "MAN",
        mercedes: "MERCEDES",
        nissan: "NISSAN",
        opel: "OPEL - VAUXHALL",
        peugeot: "PEUGEOT",
        renault: "RENAULT",
        toyota: "TOYOTA",
        volkswagen: "VOLKSWAGEN"
      }
    },
    frameType: {
      title: "Frame type"
    },
    drivingPosition: {
      title: "driving position"
    },
    fixationFrame: {
      title: "Fixation frame"
    },
    benchseatPosition: {
      title: "Benchseat position",
      maxiFront: "Maximum to the front",
      maxiRear: "Maximum to the rear",
      other: "Other",
    },
    sleepingHeight: {
      title: "Sleeping height"
    },
    sdecs: {
      title: "SDEC + SDECS (cutout on the 2nd Backrest)",
      values: {
        without: "Without",
        "80_X_100": "80X100",
        "90_X_100": "90X100",
        "90_X_140": "90X140",
        "100_X_200": "100X200",
        "140_X_140": "140X140",
        "140_X_200": "140X200"
      }
    },
    thermoflex: {
      title: "3D Matress"
    },
    isofix: {
      title: "ISOFIX Toptether"
    },
    squareArticulation: {
      title: "Square on articulation"
    },
    wheelArchOffsetHeight: {
      title: "Height under offset for wheel arch"
    },
    wheelArchOffsetLeft: {
      title: "Wheel arch offset on the left"
    },
    wheelArchOffsetRight: {
      title: "Wheel arch offset on the right"
    },
    fixedFrameVersion: {
      title: "Fixed frame version",
      values: {
        without: "Without fixed frame",
        fixed: "Fixed frame",
        c64: "C64",
        c66d: "C66D",
        c66g: "C66G"
      }
    }
  }
}
