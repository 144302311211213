import React from "react";

import "./textfield.scss";

interface TextFieldProps {
  value: string,
  placeholder: string,
  onChange: (value: string) => void
}

const TextField = ({
  value,
  placeholder,
  onChange
}: TextFieldProps) => {
  return (
    <input className="textfield" placeholder={placeholder} type="text" value={value} onChange={(e) => onChange(e.target.value)} />
  )
}

export default TextField;