export default {
  previous: "Vorherige",
  next: "Nächste",
  validate: "Bestätigen",
  download: "Herunterladen",
  completingSetup: "Abschluss der Konfiguration",
  enterConfigurationCode: "Geben Sie den Konfigurationscode ein",
  left: "Links",
  right: "Rechts",
  yes: "Ja",
  no: "nein",
  with: "Mit",
  without: "Ohne",
  restart: "Neustart",
  other: "Andere",
  steps: {
    seatWidth: {
      title: "Sitzbankbreite",
      seatNumber: {
        title: "Anzahl der Plätze",
        label: "{{count}} Platz",
        label_plural: "{{count}} Plätzen"
      },
      seatWidth: {
        title: "Sitzbankbreite",
        labelLeft: "{{count}} mm links",
        labelRight: "{{count}} mm rechts",
        label: "{{count}} mm"
      }
    },
    sleepLength: {
      title: "Länge von Schlafstelle",
      labelAltair: "ALTAIR (Länge von Schlafstelle : {{count}} mm)",
      labelNeptune: "NEPTUNE (Länge von Schlafstelle : {{count}} mm)"
    },
    armRest: {
      title: "Armstütze",
      without: "Ohne",
      left: "Links",
      right: "Rechts",
      leftRight: "Links und rechts",
      center: "Zentral",
      centerLeftRight: "Zentral, links und rechts",
      all: "2 in der Mitte, links und rechts"
    },
    clothDist: {
      title: "Gewebeverteilung",
      without: "ohne Stoff",
      one: "ein einziger Stoff",
      two: "zweifarbig"
    },
    clothes: {
      title: "Stoff",
      label: "Stoff {{count}}",
      labelRev1: "Stoff 1 (Gepolstertes Band)",
      labelRev2: "Stoff 2 (Rest des Sitzes)",
      noElementMessage: "Sie haben ohne Stoff gewählt, gehen Sie direkt zum nächsten",
      constructorsLabel: "Auswahl",
      texturesLabel: "Stoff",
      brands: {
        rib: "RIB",
        ford: "FORD",
        mercedes: "MERCEDES",
        opel: "OPEL",
        psa: "PSA",
        renault: "RENAULT",
        volkswagen: "VOLKSWAGEN"
      },
      textures: {
        draduro7001: "Draduro 7001",
        draduro8009: "Draduro 8009",
        draduro8032: "Draduro 8032",
        draduro8212: "Draduro 8212",
        draduro8335: "Draduro 8335",
        draduro8901: "Draduro 8901",
        draduro8915: "Draduro 8915",
        enduro470: "Enduro 470",
        enduro1478: "Enduro 1478",
        enduro1677: "Enduro 1677",
        enduro2687: "Enduro 2687",
        enduro7005: "Enduro 7005",
        enduro8015: "Enduro 8015",
        enduro8440: "Enduro 8440",
        enduro8504: "Enduro 8504",
        enduro8514: "Enduro 8514",
        enduro8800: "Enduro 8800",
        enduro8841: "Enduro 8841",
        enduro8900: "Enduro 8900",
        enduro8923: "Enduro 8923",
        resistRosa: "Resist Rosa",
        resistAzaleaBlack: "Resist Azalea Black",
        black: "DRADURON",
        fTransitcustom: "FORD TRANSIT CUSTOM",
        forduni: "FORD MAX",
        mercedeslima: "MERCEDES LIMA",
        mercedestunja: "TUNJA Motiv",
        mercedestunjauni: "Tunja einfarbig",
        limamotif: "LIMA motiv",
        limauni: "LIMA Einfarbig",
        oVivaroconnectm: "VIVARO CONNECT M",
        oVivaroconnectu: "VIVARO CONNECT U",
        peugeotboxer: "PEUGEOT BOXER",
        psakobrasilia: "PSA KO BRASILIA",
        psakocuritiba: "PSA KO CURITIBA",
        fiatscudo: "FIAT SCUDO",
        scudobleuuni: "SCUDO BLAU Einfarbig",
        fDucato2014m: "FIAT Crêpe 157 Gris",
        fDucato2014u: "FIAT Crêpe 157 Noir",
        renaultdino: "RENAULT DINO",
        renaultkario: "RENAULT KARIO",
        safariBlack: "SAFARI NOIR",
        t5boxuni: "T5 BOX Einfarbig",
        tasamouni: "TASAMO Einfarbig",
        vwaustin: "VW AUSTIN",
        vwbricks: "VW BRICKS",
        vwdoublegrid: "VW DOUBLE GRID",
        vwt4inca: "VW T4 INCA",
        vwt5incaii: "VW T5 INCA II",
        vwt5place: "VW T5 PLACE",
        vwt6simora: "VW T6 SIMORA",
        titane: "TITANE",
        kompo: "RENAULT KOMPO"
      }
    },
    handlePad: {
      title: "Handgriff und versetzt",
      handleAxleLeft: "Handgriff in der Achse links",
      handleAxleRight: "Handgriff in der Achse rechts",
      integratedAxleLeft: "Hoch Handgriff links",
      integratedAxleRight: "Hoch Handgriff rechts",
      handleAxlePdadLeft: "Handgriff in der Achse links + {{count}} versetzt",
      handleAxlePdadRight: "Handgriff in der Achse rechts + {{count}} versetzt",
    },
    constructor: {
      title: "Hersteller",
      titleType: "Type",
      brands: {
        citroen: "CITROËN",
        fiat: "FIAT",
        ford: "FORD",
        man: "MAN",
        mercedes: "MERCEDES",
        nissan: "NISSAN",
        opel: "OPEL - VAUXHALL",
        peugeot: "PEUGEOT",
        renault: "RENAULT",
        toyota: "TOYOTA",
        volkswagen: "VOLKSWAGEN"
      }
    },
    frameType: {
      title: "Gurthaltebock Typ"
    },
    drivingPosition: {
      title: "Fahrposition"
    },
    fixationFrame: {
      title: "Befestigungsrahmen"
    },
    benchseatPosition: {
      title: "Sitzbank Pozition",
      maxiFront: "Maximal vor",
      maxiRear: "Maximal hinten",
      other: "Andere Pozition",
    },
    sleepingHeight: {
      title: "Schlafplatzhöhe"
    },
    sdecs: {
      title: "SDEC + SDECS (Ausschnitt auf dem Rückenkissen)",
      values: {
        without: "Ohne",
        "80_X_100": "80X100",
        "90_X_100": "90X100",
        "90_X_140": "90X140",
        "100_X_200": "100X200",
        "140_X_140": "140X140",
        "140_X_200": "140X200"
      }
    },
    thermoflex: {
      title: "3D Schlapolsterauflage"
    },
    isofix: {
      title: "ISOFIX Toptether"
    },
    squareArticulation: {
      title: "Quadrat auf Teller"
    },
    wheelArchOffsetHeight: {
      title: "Höhe unter Versatz für Radlauf"
    },
    wheelArchOffsetLeft: {
      title: "Radlaufversatz links"
    },
    wheelArchOffsetRight: {
      title: "Radlaufversatz rechts"
    },
    fixedFrameVersion: {
      title: "Fester Gurthaltebock",
      values: {
        without: "Ohne Fester Gurthaltebock",
        fixed: "Fester Gurthaltebock",
        c64: "C64",
        c66d: "C66D",
        c66g: "C66G"
      }
    }
  }
}
