import { getLocalStorageDataLength, LocalStorageData } from "./localstorage_data";
import { getLocalStorageData } from "./utils";

const STEP_SEPARATOR = "#";
const OBJECT_SEPARATOR = "+";

/**
 * Scopema code parser to LocalStorageData
 * 
 * @param {string | null} code Scopema code to parse
 * @returns {LocalStorageData | null} return null if code is not correct
 */
export function parseCode(code : string | null) : LocalStorageData | null {
    if (!code) return null 

    let splittedCode = code.split(STEP_SEPARATOR)

    if (splittedCode.length !== getLocalStorageDataLength()) return null

    let data = getLocalStorageData(false)

    Object.keys(data).forEach((key, index) => {
        let objectCode = splittedCode[index]
        let splittedObjectCode = objectCode.split(OBJECT_SEPARATOR)
        Object.keys((data as any)[key]['data']).forEach((objectKey, objectIndex) => {
            (data as any)[key]['data'][objectKey] = splittedObjectCode[objectIndex]
        })
    })

    return data
}

export function generateCode(data : LocalStorageData) : string {
    let arrayCode : string[] = []

    Object.keys(data).forEach((key) => {
        let objectDataArray : string[] = []
        if ((data as any)[key]['data']) {
            Object.keys((data as any)[key]['data']).forEach((objectKey) => {
                objectDataArray.push((data as any)[key]['data'][objectKey])
            })
        }
        arrayCode.push(objectDataArray.join(OBJECT_SEPARATOR))
    })

    return arrayCode.join(STEP_SEPARATOR)
}