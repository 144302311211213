import React from "react";
import './breadcrumb-mobile-group-dot.scss';

interface ButtonProps {
  key: string,
  active: boolean,
  selected: boolean,
  onClick: () => void,
}

export default class BreadcrumbMobileGroupDot extends React.Component <ButtonProps, {}> {

  constructor (props : ButtonProps) {
    super (props)
  }

  render () {
    return (
      <div onClick={()=>this.props.onClick()} className={`breadcrumb-mobile-group-dot ${this.props.selected?'selected':''} ${this.props.active?'active':''}`}/>
    )
  }

}
