export interface TypeVehicleData {
    brand: string | null,
    type: number | null
}

export interface PositionVehicleData {
    position: number | null
}

export interface InterfaceVehicleData {
    interface: number | null
}

export interface SeatPositionData {
    position: number | null
}

export interface SeatWidthData {
    width: string | null
}

export interface SleepHeightData {
    height: number | null
}

export interface SleepLengthData {
    length: string | null
}

export interface ArmrestData {
    armrest: string | null
}

export interface HandleAndPadData {
    handleAndPad: string | null
}

export interface SDECSData {
    sdecs: string | null
}

export interface IsofixData {
    isofix: number | null
}

export interface ThermoflexData {
    thermoflex: number | null
}

export interface ClothDistributionData {
    clothDistribution: string | null
}

export interface ClothesData {
    cloth_1: string | null,
    cloth_2: string | null
}

export interface LocalStorageData {
    seatPos: { data: SeatPositionData, group: string },
    seatWidth: { data: SeatWidthData, group: string },
    sleepLength: { data: SleepLengthData, group: string },
    armrest: { data: ArmrestData, group: string },
    clothDist: { data: ClothDistributionData, group: string },
    clothes: { data: ClothesData, group: string }
}

export function getBaseObject(){
    return {
        seatPos: { data: { position: null }, group: 'seat' },
        seatWidth: { data: { width: null }, group: 'seat' },
        sleepLength: { data: { length: null }, group: 'seat' },
        armrest: { data: { armrest: null }, group: 'headrest' },
        clothDist: { data: { clothDistribution: null }, group: 'colors' },
        clothes: { data: { cloth_1: null, cloth_2: null }, group: 'colors'}
    } as LocalStorageData
}

export function getLocalStorageDataLength() {
    return Object.keys(getBaseObject()).length;
}
