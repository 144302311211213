import { GameEngineData, initEmpty, EMaterial } from "./gameengine_data"
import { LocalStorageData, getBaseObject } from "./localstorage_data"
import StepKey from "../interfaces/StepKey";


function loadFromPersistentGameEngineData(): GameEngineData {
    let obj
    if (window.localStorage['scopema_godot'] == undefined) obj = initEmpty()
    else obj = JSON.parse(window.localStorage['scopema_godot'])
    return attachSetProxy(obj, function (target: any, key: any, value: any) {
        target[key] = value
        window.localStorage['scopema_godot'] = JSON.stringify(getGameEngineData())
        return true
    }) as GameEngineData;
}

// Use to autosave modification in persistent storage
function attachSetProxy(obj: any, callback: (target: any, key: any, value: any) => boolean) {
    Object.keys(obj).forEach(function (key) {
        let value: any = obj[key]
        if (value instanceof Object) {
            obj[key] = attachSetProxy(value, callback)
        }
    })
    var targetProxy = new Proxy(obj, {
        set: function (target: any, key: any, value: any) {
            return callback(target, key, value)
        }
    })
    return targetProxy
}

export function getGameEngineData(): GameEngineData {
    if (getIFrameWindow().game_data === undefined || getIFrameWindow().game_data === null) getIFrameWindow().game_data = loadFromPersistentGameEngineData()
    return getIFrameWindow().game_data as GameEngineData
}

export function getLocalStorageData(proxy = true): LocalStorageData {
    let obj : LocalStorageData = getBaseObject();
    if (window.localStorage['scopema'] != undefined) obj = JSON.parse(window.localStorage['scopema'])

    if (proxy) {
        obj = attachSetProxy(obj, function (target: any, key: any, value: any) {
            target[key] = value
            window.localStorage['scopema'] = JSON.stringify(obj as LocalStorageData)
            return true
        })
    }

    return obj as LocalStorageData
}

export function resetLocalStorageData() {
    window.localStorage.clear();
}

export function getFirstInvalidStepKeyOrLast(steps : any): StepKey {
    let lastValue : any;
    for (const [key, value] of Object.entries(steps)){
        if(checkStepResponse(key) !== true){
            return {key: key}
        }
        lastValue = {key: key};
    }
    return lastValue;
}

export function checkStepResponse(key: string): string[] | true {
    let errors: string[] = []
    let stepData = (getLocalStorageData() as any)[key].data
    if (stepData == undefined) {
        errors[0] = 'Veuillez remplir tout les champs';
    } else {
        for (const [key, value] of Object.entries(stepData)){
            if (value == null || value === "") {
                errors[0] = 'Veuillez remplir tout les champs';
            }
        }
    }
    return (errors.length == 0) ? true : errors
}

export function nextKey(steps: any, actualKey:StepKey): StepKey{
    let keysArray = Object.keys(steps);
    if(keysArray.findIndex(element => element == actualKey.key) >= keysArray.length-1){
        return {key: keysArray[keysArray.length-1]}
    }
    return{key: keysArray[keysArray.findIndex(element => element == actualKey.key) +1]};
}

export function prevKey(steps: any, actualKey:StepKey): StepKey{
  let keysArray = Object.keys(steps);
  if(keysArray.findIndex(element => element == actualKey.key) <= 0){
    return {key: keysArray[0]}
  }
  return{key: keysArray[keysArray.findIndex(element => element == actualKey.key) -1]};
}

export function getIFrameWindow(): any {
    let iframe = document.getElementById('godot_render') as HTMLIFrameElement
    if (iframe != null) {
        return iframe.contentWindow
    } else {
        return {
            contentWindow: {}
        }
    }
}

export function godotIsReady(): boolean {
    return getIFrameWindow().godot_ready === true;
}

export function setCustomOptions(headrestPosition: Boolean) {
    getGameEngineData().data.dossiers = headrestPosition ? "appuie_tete_position_haute" : "appuie_tete"
}

export function setMaterial(material: EMaterial, name: string) {
    let data = getGameEngineData();
    if (data.materials !== undefined) {
        switch(material) {
            case EMaterial.MATERIAL_GLOBAL:
                data.materials.material_1 = name;
                break;
            case EMaterial.MATERIAL_BACK:
                data.materials.material_2 = name;
                break;
            case EMaterial.MATERIAL_MATELASSE:
                data.materials.material_3 = name;
                break;
        }
    }
}
