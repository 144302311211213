import React from "react";
import BreadcrumbCircle from '../../items/breadcrumb/breadcrumb-circle/breadcrumb-circle';
import BreadcrumbMobileGroup from "../../items/breadcrumb/breadcrumb-mobile-group/breadcrumb-mobile-group";
import StepKey from "../../../interfaces/StepKey";
import './breadcrumb.scss';

interface Props {
  steps : any,
  stepKey : StepKey,
  onSelect : (key:string) => void,
  lastValid : StepKey,
  groups : any
}

export default class Breadcrumb extends React.Component <Props, {}> {

  constructor (props : Props) {
      super (props)
  }

  render () {

      let circles = [];
      let mobileGroups = [];
      let active : boolean = true;
      let group : string = "";
      let groupDots = [];
      let selectedGroup : string = this.props.steps[this.props.stepKey.key].group;
      let nbEl = Object.keys(this.props.steps).length;
      let position = 0;
      for (const [key, value] of Object.entries(this.props.steps)){
          let showIcon = false;
          if(group != (value as any).group){
              if(group !== ""){
                  mobileGroups.push(<BreadcrumbMobileGroup key={`breadcrumb-circle-${group}`} childStartKey={`breadcrumb-circle-${group}`} dots={groupDots} icon_path={this.props.groups[group]} onClick={() => {}}/>)
              }
              group = (value as any).group;
              showIcon = true;
              groupDots = []
          }
          groupDots.push({active: active, selected: this.props.stepKey.key == key})
          circles.push(<BreadcrumbCircle key={`breadcrumb-circle-${key}`} active={active} selected={this.props.stepKey.key == key} selected_icon={showIcon ? selectedGroup === group : false} icon_path={showIcon ? this.props.groups[group] : null} onClick={() => this.props.onSelect(key)} />);
          if(this.props.lastValid.key == key){
              active = false;
          }
          position += active ? 1 : 0;
      }
      mobileGroups.push(<BreadcrumbMobileGroup key={`breadcrumb-circle-${group}`} childStartKey={`breadcrumb-circle-${group}`} dots={groupDots} icon_path={this.props.groups[group]} onClick={() => {}}/>)


    return (
        <div className={`breadcrumb`}>
          <div className={`breadcrumb-pc`}>
            <div className="bar">
              <div className="inner-unpassed" style={{width: `calc(100% - 100%/${nbEl})`,left: `calc(100%/${nbEl*2})`}}/>
              <div className="inner" style={{width: `calc(100%/${nbEl}*${position})`,left: `calc(100%/${nbEl*2})`}}/>
              {circles}
            </div>
          </div>
          <div className={`breadcrumb-mobile`}>
              {mobileGroups}
          </div>

        </div>

      )
  }

}
